import React, { useState, useEffect } from "react";

const videoServiceBaseUrl = 'https://media.publit.io';

/*
publitio.call('/players/show/<player ID>', 'GET')
  .then(data => { console.log(data) })
  .catch(error => { console.log(error) });
*/


export default ({ player: { videoPlayerId, videoId } }) => (
<video
  id={videoPlayerId}
  className="video-player"
  style={{ width: '100%', boxSizing: 'border-box', boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, .25)' }}
  oncontextmenu="return false;"
  controlslist="nodownload"
  poster={`${videoServiceBaseUrl}/${videoId}.jpg`}
  playsinline
>
  <source src={`${videoServiceBaseUrl}/file/h_480/${videoId}.webm`} type="video/webm" />
  <source src={`${videoServiceBaseUrl}/file/h_480/${videoId}.mp4`} type="video/mp4" />
  <source src={`${videoServiceBaseUrl}/file/h_480/${videoId}.ogv`} type="video/ogv" />
</video>
);


/*
let startTime;
let stopTime;
//! let paused;
*/


/**
 * 
 */
export function VideoPlayerConstructor({ videoPlayerId, videoId, timeUpdateHandler, onReady }) {
  //let _startTime;
  //let _stopTime;
  //let video;// = document.querySelector(`#${videoPlayerId}`);

  this.videoPlayerId = videoPlayerId;
  this.videoId = videoId;
  this.timeUpdateHandler = timeUpdateHandler;

  setTimeout(() => {
    this.videoPlayer = document.querySelector(`#${videoPlayerId}`);

    if (onReady) {
      onReady(); // initVideoPlayer();
    }
  }, 1);
}

/*
    VideoPlayerConstructor.prototype.play = function load(videoData) {
      youtubePlayer.loadVideoById({
        //'videoId': 'bHQqvYy5KYo',
        'videoId': videoData.source, //! 'l9ee_eN4AYI',
        //'startSeconds': 5,
        //'endSeconds': 60,
        'suggestedQuality': 'large',
      });

      //! this.play(startTime, stopTime);
    };
*/

VideoPlayerConstructor.prototype.play = function (startTime = 0, duration = 9999999) {
  this._startTime = startTime;
  this._stopTime = startTime + duration;
  console.log("VideoPlayer.play: _startTime=" + this._startTime + " _stopTime=" + this._stopTime);
  //! currClip.video.currentTime = startTime;
  this.videoPlayer.play();//! _startTime, _stopTime);

//! TEMP!!! null TO AVOID AUTOMATIC REWIND (should just unpause if was paused)
if (startTime !== null)
  setTimeout(() => {
      this.seek(this._startTime);
  }, 1000);

  if (this.timeUpdateHandler) {
    //! currClip.video.addEventListener('timeupdate', this.timeUpdateHandler);
    // TODO USE onPlayerStateChange STATE INSTEAD OF THE TIMER TO DETECT STOPPING OF PLAY ETC
    setTimeout(this.timeUpdateHandler, duration * 1000);
  }
};

VideoPlayerConstructor.prototype.pause = function pause() {
  this.videoPlayer.pause();
};

VideoPlayerConstructor.prototype.seek = function seek(time) {
  console.log("VideoPlayer.seek: time=" + time + " this.videoPlayer.currentTime=" + this.videoPlayer.currentTime);
  this.videoPlayer.currentTime = time;
};

VideoPlayerConstructor.prototype.seekEnd = function seekEnd() {
  this.seek(this.videoPlayer.duration);
};

VideoPlayerConstructor.prototype.seekBeginning = function seekBeginning() {
  //youtubePlayer.seek(_startTime);
  //youtubePlayer.playVideo(_startTime, _stopTime);
//TODO MOVE TO HIGHER LEVEL VIDEOPLAYER REWIND FN
//TODO  this.play(this._startTime, this._stopTime - this._startTime);
  this.seek(0);
};

VideoPlayerConstructor.prototype.stop = function stop() {
  this.videoPlayer.stop();
};

VideoPlayerConstructor.prototype.isPaused = function isPaused() {
};
