import React, { useState, useEffect } from "react";

// TEMP { checkAnswers, submitQuizForm }
import VideoPlayer__Component, { checkAnswers, submitQuizForm } from "../components/VideoPlayer"

//import PublitioAPI from './node_modules/publitio_js_sdk/build/publitio-api.min.js';
//! import PublitioAPI from 'publitio_js_sdk';

// Tell webpack this JS file uses this image
// The import result is the URL of your image
import AspectAccompliImg from "../../content/assets/aspect-accompli.png"
import AspectIteratifImg from "../../content/assets/aspect-iteratif.png"
import AspectPonctuelImg from "../../content/assets/aspect-ponctuel.png"
import AspectProgressifImg from "../../content/assets/aspect-progressif.png"
//import BackgroundImg from "../../content/assets/backgrounds/CARTOON_PATTERN_20.jpg"

//TODO TEMP?! SEEMED TO BRING IN BLOG POST CONTENT WITH THIS FILE NAME? (MAYBE HOT-LOADING WEIRDNESS???)
import BlogLayout from "../../node_modules/gatsby-theme-blog/src/components/layout"

import quizData from "../../src/quiz-example.json"
const quiz = quizData.quiz;

let numCorrect = 0;
let numQuestions = quiz.questions.length;

const answers = Array(numQuestions).fill(undefined);

//import Header from "../../node_modules/gatsby-theme-blog/src/components/header"
//TODO import { Trianglify } from "trianglify"
//! const Trianglify = require('trianglify'); // only needed in node.js


//! export default props => <BlogLayout {...props}>{props.children}</BlogLayout>

export default (props) => {

if (typeof window !== `undefined`) {

setTimeout(function () {
        let Trianglify = window['Trianglify'];
    //return;

    //function trianglifyHeader() {
// https://trianglify.io/p/w:1440!h:900!x:PRGn!v:0!c:0.18!s:74e3ck
// https://github.com/qrohlf/trianglify
// http://qrohlf.com/trianglify/
const pattern = Trianglify({
width: window.innerWidth,
height: 320, //window.innerHeight,
// cell_size: 100.18,
// variance: 0,
// seed: 74,
});

if (true) {
    const gatsbyFocusWrapper = document.querySelector('#gatsby-focus-wrapper');
    const header = gatsbyFocusWrapper.querySelector('header');
    const canvas = header.appendChild(pattern.canvas());
    canvas.style = `
      position: absolute;
      top: 0;
      z-index: -1;
      height: 320px;
      width: 100%;
    `;

    //const rootPath = `${__PATH_PREFIX__}/`
    //if (location.pathname === rootPath) {
    //}
    //else {
      let mainHeading = gatsbyFocusWrapper.querySelector('main > h1');

    if (!mainHeading) {
        // Try to find home page heading
        mainHeading = header.querySelector('h1');
    }

if (mainHeading) {
    mainHeading.style.textTransform = 'uppercase';
    mainHeading.style.color = '#eee';
    mainHeading.style.opacity = .6;
    mainHeading.style.fontSize = '4.5em';
    mainHeading.style.textShadow = 'rgb(0, 0, 0) 2px 2px 4px';
}

    const mainContent = gatsbyFocusWrapper.querySelector('main > h1 + p + *');
    if (mainContent) {
      mainContent.style.marginTop = '70px';
    }
}

}, 1);

}


/*
const handleChange = event => {
  setValue(event.target.value);
};
*/

const checkAnswer = index => {
  //event.preventDefault();
  /*
  if (value) {
    setList(list.concat(value));
  }

  setValue('');
  */

/*
const quizForm = document.querySelector('#quiz-form');

const checkedCheckbox = quizForm.querySelector('input[name="question"]:checked');
if (!checkedCheckbox) {
  alert("Veuillez répondre à toutes les questions !");
  return;
}
answers[questionIndex] = checkedCheckbox.value === 'true';
*/

  nextQuestion(null, index);
};

const previousQuestion = event => {
  if (event) event.preventDefault();
  setQuestionIndex(questionIndex - 1);
};

const nextQuestion = (event, index = questionIndex) => {
  if (event) event.preventDefault();

  console.log(`index=${index} questionIndex=${questionIndex} numQuestions=${numQuestions}`)
  //TODO SHOULD ONLY DO THIS IF ALL QUESTIONS ANSWERED (I.E. NONE SKIPPED WITHOUT BEING ANSWERED)
  if (questionIndex == numQuestions - 1) {
    alert("Checking answers..");
    //numCorrect == numQuestions
    alert("checkAnswers: videoPlayer WHAT TO DO HERE???");
    // TODO checkAnswers(videoPlayer, numQuestions, numCorrect);
  }
  else {
    if (index == questionIndex) {
      setQuestionIndex(questionIndex + 1);
    }
  }
};

const [questionIndex, setQuestionIndex] = useState(0);


return <>

<div id="lamp">
  <div id="top"></div>
  <div id="glass">
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  <div class="bubble"></div>
  </div>
  <div id="bottom"></div>
</div>

{/* TEMP!!! */}
{/*<script src="https://cdnjs.cloudflare.com/ajax/libs/trianglify/2.0.0/trianglify.min.js"></script>*/}
{/*<pre>{JSON.stringify(quiz)}</pre>*/}

{/*
<div oncontextmenu="return false;" id="publitio_DVV446Tv" class="publitioPlayer" >
  <div id="publitio_ph_DVV446Tv" class="publitioPlaceHolder" ></div>
</div>
<link href="//static.publit.io/css/publitio_player.min.css" rel="stylesheet" />
*/}

<BlogLayout {...props}>


    <br />
    <br />

    <ul id="video-timeline" className="vcv-timeline">
    <li className="vcv-timeline-item" data-step="1" data-step-title="Imparfait / Passé composé : Partie 1"><span>Imparfait&nbsp;et Passé&nbsp;composé&nbsp;1</span></li>
    <li className="vcv-timeline-item" data-step="2" data-step-title="Imparfait / Passé composé : Partie 2"><span>Imparfait&nbsp;et Passé&nbsp;composé&nbsp;2</span></li>
    <li className="vcv-timeline-item" data-step="3" data-step-title="Imparfait / Passé composé : Partie 3"><span>Imparfait&nbsp;et Passé&nbsp;composé&nbsp;3</span></li>
    <li className="vcv-timeline-item" data-step="4" data-step-title="Imparfait / Passé composé : Partie 4"><span>Imparfait&nbsp;et Passé&nbsp;composé&nbsp;4</span></li>
    <li className="vcv-timeline-item" data-step="5" data-step-title="Imparfait / Passé composé : Partie 5"><span>Imparfait&nbsp;et Passé&nbsp;composé&nbsp;5</span></li>
    </ul>

{/*
    <VideoPlayer__Component id="video-12345" name="iMPARFAIT-PASSE-COMPOSE-VIDEO-1" />
*/}

    <br />

    <div style={{ display: 'flex', flexDirection: 'column', xjustifyContent: 'space-around', xmargin: '80px -50%' }}>
      <VideoPlayer__Component
        id="video-1"
        title="Leçon 1 : « Imparfait / Passé Composé 1<sup>ère</sup> »"
        videoId={{ vimeo: 416652825, publitio: 'iMPARFAIT-PASSE-COMPOSE-VIDEO-1' }}
        theme="orange"
      />

      <br />
      <br />

      <VideoPlayer__Component
        id="video-2"
        title="Leçon 2 : « Imparfait / Passé Composé 2<sup>ème</sup> »"
        videoId={{ vimeo: 416655898, publitio: 'Impt-pc-video-2' }}
        theme="cyan"
        hostingService="vimeo"
      />
    </div>

    <br />

    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', margin: '80px -50%' }}>
      <VideoPlayer__Component
        id="video-3"
        title="Leçon 3 : « Imparfait / Passé Composé 3<sup>ème</sup> »"
        videoId={{ vimeo: 416652825, publitio: 'iMPARFAIT-PASSE-COMPOSE-VIDEO-1' }}
        theme="pink"
        style={{ margin: '0 1%' }}
      />
      <VideoPlayer__Component
        id="video-4"
        title="Leçon 4 : « Imparfait / Passé Composé 4<sup>ème</sup> »"
        videoId={{ vimeo: 416655898, publitio: 'Impt-pc-video-2' }}
        theme="green"
        style={{ margin: '0 1%' }}
        hostingService="publitio"
      />
      <VideoPlayer__Component
        id="video-5"
        title="Leçon 5 : « Imparfait / Passé Composé 5<sup>ème</sup> »"
        videoId={{ vimeo: 416652825, publitio: 'iMPARFAIT-PASSE-COMPOSE-VIDEO-1' }}
        theme="cyan"
        style={{ margin: '0 1%' }}
      />
      <VideoPlayer__Component
        id="video-6"
        title="Leçon 6 : « Imparfait / Passé Composé Conclusion »"
        videoId={{ vimeo: 416655898, publitio: 'Impt-pc-video-2' }}
        theme="orange"
        style={{ margin: '0 1%' }}
        hostingService="publitio"
      />
    </div>

{/*
    <div style={{ display: 'flex', flexDirection: 'row', margin: '80px -50%' }}>
      <VideoPlayer__Component style={{ Xwidth: '46%', margin: '0 2%' }} id="video-1234" name="iMPARFAIT-PASSE-COMPOSE-VIDEO-1" theme="orange" />
      <VideoPlayer__Component style={{ Xwidth: '46%', margin: '0 2%' }} id="video-6" name="Impt-pc-video-2" theme="green" />
      <VideoPlayer__Component style={{ Xwidth: '46%', margin: '0 2%' }} id="video-123" name="iMPARFAIT-PASSE-COMPOSE-VIDEO-1" theme="cyan" />
      <VideoPlayer__Component style={{ Xwidth: '46%', margin: '0 2%' }} id="video-7" name="Impt-pc-video-2" theme="pink" />
    </div>
*/}

    {/* <pre>{JSON.stringify(quiz.questions)}</pre> */}

    {/*<form id="quiz-form" class="quiz" onSubmit={(event) => submitQuizForm(event, currQuizIndex)}>*/}
    <form id="quiz-form" class="quiz" onSubmit="">
        {quiz.title && <h2>{quiz.title}</h2>}
        <h3>Maintenant, veuillez répondre aux questions suivantes afin de passer à la partie prochaine :</h3>
        <table>
            <thead>
                <tr>
                    <th></th>
                    {quizData.defaults.answers.map(answer => <th>{answer[0].toUpperCase() + answer.slice(1)}</th>)}
                    <th>{!false && 'Résultat'}</th>
                </tr>
            </thead>
            <tbody>
              {quiz.questions.map((questionObj, index) => {
                if (index > questionIndex) return;
                const questionArr = Object.entries(questionObj);
                let [question, answers] = questionArr[0];
                if (!answers || typeof answers != 'object') answers = questionObj.answers;
                if (!answers) answers = quiz.answers;
                if (!answers) answers = quizData.default.answers;
                if (typeof answers == 'object' && !Array.isArray(answers)) answers = Object.keys(answers);
                const maxRadioChoices = 2;
                const isSelectControl = answers.length > maxRadioChoices;

                //! TEMP!!!
                const inputType = answers[0].type;
                console.log(`answers=${answers} inputType=${inputType} questionArr=${JSON.stringify(questionArr)}`);

                // TODO THIS KIND OF STUFF SHOULD BE PREPROCESSED FROM THE YAML -> JSON CONVERSION...
                question = question.replace(/_(.*)_/, "<em>$1</em>");
                question = question.replace(/\*(.*)\*/, "<strong>$1</strong>");

                question = question.replace(/src="{(AspectAccompliImg)}"/, `src="${AspectAccompliImg}"`);
                question = question.replace(/src="{(AspectProgressifImg)}"/, `src="${AspectProgressifImg}"`);

                if (inputType) {
                  return (
                    <td>
                      <input type={inputType} placeholder={`Veuillez saisir un ${inputType}`} />
                    </td>
                  );
                }

                return (
                  <tr className="question">
                      <td dangerouslySetInnerHTML={{__html: question}} />
                      {isSelectControl
                      ? <td colSpan={quizData.defaults.answers.length}>
                          <select name={`question-${index}`} onChange={() => checkAnswer(index)}>
                          {answers.map(answer => {
                              if (typeof answer == 'object') answer = Object.keys(answer)[0];
                              return (
                                <option value={answer}>
                                    {answer[0].toUpperCase() + answer.slice(1)}
                                </option>
                              );
                            })}
                          </select>
                        </td>
                      : answers.map(answer => {
                        if (typeof answer == 'object') answer = Object.keys(answer)[0];
                        return (
                          <td>
                            <label>
                              {answer[0].toUpperCase() + answer.slice(1)}
                              <input type="radio" name={`question-${index}`} value="true" onChange={() => checkAnswer(index)} />
                            </label>
                          </td>
                        )
                      })}
                      <td className="result"></td>
                  </tr>
                )
              })}
              {/*Object.entries(quiz.questions[questionIndex]).map(([question, answers], index) => (
                  <tr className="question">
                    <td>{question} index={index}</td>
                    <td><input type="radio" name={`question-${index}`} value="true" onChange={checkAnswer} /></td>
                    <td><input type="radio" name={`question-${index}`} value="false" onChange={checkAnswer} /></td>
                    <td className="result"></td>
                  </tr>
                ))
              */}
            </tbody>
            <tfoot>
                <tr>
                  <td>
                      <button onClick={previousQuestion} style={{ float: 'right', marginTop: '40px', fontSize: '1.3em' }}>Question précédente</button>
                  </td>
                  <td>
                      <button type="submit" style={{ float: 'right', marginTop: '40px', fontSize: '1.3em' }}>Vérifier vos réponses</button>
                  </td>
                  <td>
                      <button onClick={nextQuestion} style={{ float: 'right', marginTop: '40px', fontSize: '1.3em' }}>Ignorer question</button>
                  </td>
                </tr>
            </tfoot>
        </table>
    </form>




    <form id="quiz-1">
        <h3>Maintenant, veuillez répondre aux questions suivantes afin de passer à la partie prochaine :</h3>
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th>Vrai</th>
                    <th>Faux</th>
                    <th>Résultat</th>
                </tr>
            </thead>
            <tbody>
                <tr className="question">
                    <td><em>J'ai mangé</em> est à l'imparfait</td>
                    <td><input type="radio" name="question-1" value="true" /></td>
                    <td><input type="radio" name="question-1" value="false" /></td>
                    <td className="result"></td>
                </tr>
                <tr className="question">
                    <td><em>J'ai mangeais</em> est à l'imparfait</td>
                    <td><input type="radio" name="question-2" value="true" /></td>
                    <td><input type="radio" name="question-2" value="false" /></td>
                    <td className="result"></td>
                </tr>
                <tr className="question">
                    <td><em>Elle a dormi</em> est au passé composé</td>
                    <td><input type="radio" name="question-3" value="true" /></td>
                    <td><input type="radio" name="question-3" value="false" /></td>
                    <td className="result"></td>
                </tr>
                <tr className="question">
                    <td>Le temps et l’aspect d’un verbe, c’est pareil</td>
                    <td><input type="radio" name="question-4" value="true" /></td>
                    <td><input type="radio" name="question-4" value="false" /></td>
                    <td className="result"></td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <input type="submit" value="Vérifier vos réponses" style={{ float: 'right', marginTop: '40px', fontSize: '1.3em' }} />
                    </td>
                </tr>
            </tfoot>
        </table>
    </form>

    <form id="quiz-2">
        <h3>Maintenant, veuillez répondre aux questions suivantes afin de passer à la partie prochaine :</h3>
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th>Vrai</th>
                    <th>Faux</th>
                    <th>Résultat</th>
                </tr>
            </thead>
            <tbody>
                <tr className="question">
                    <td>Le temps est représenté en Europe par une ligne horizontale</td>
                    <td><input type="radio" name="question-1" value="true" /></td>
                    <td><input type="radio" name="question-1" value="false" /></td>
                    <td className="result"></td>
                </tr>
                <tr className="question">
                    <td>Il y a 4 temps sur la ligne du temps :<br />passé composé, passé, présent, futur</td>
                    <td><input type="radio" name="question-2" value="true" /></td>
                    <td><input type="radio" name="question-2" value="false" /></td>
                    <td className="result"></td>
                </tr>
                <tr className="question">
                    <td>L’imparfait situe l’action presque toujours dans le passé</td>
                    <td><input type="radio" name="question-3" value="true" /></td>
                    <td><input type="radio" name="question-3" value="false" /></td>
                    <td className="result"></td>
                </tr>
                <tr className="question">
                    <td>La différence entre le passé composé et l'imparfait est dans le temps</td>
                    <td><input type="radio" name="question-4" value="true" /></td>
                    <td><input type="radio" name="question-4" value="false" /></td>
                    <td className="result"></td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <input type="submit" value="Vérifier vos réponses" style={{ float: 'right', marginTop: '40px', fontSize: '1.3em' }} />
                    </td>
                </tr>
            </tfoot>
        </table>
    </form>

    <form id="quiz-3">
        <h3>Maintenant, veuillez répondre aux questions suivantes afin de passer à la partie prochaine :</h3>
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th>Vrai</th>
                    <th>Faux</th>
                    <th>Résultat</th>
                </tr>
            </thead>
            <tbody>
                <tr className="question">
                    <td>L’aspect verbal est un temps verbal</td>
                    <td><input type="radio" name="question-1" value="true" /></td>
                    <td><input type="radio" name="question-1" value="false" /></td>
                    <td className="result"></td>
                </tr>
                <tr className="question">
                    <td>Le verbe « éclater » a un aspect itératif</td>
                    <td><input type="radio" name="question-2" value="true" /></td>
                    <td><input type="radio" name="question-2" value="false" /></td>
                    <td className="result"></td>
                </tr>
                <tr className="question">
                    <td>« J’ai revu mes cours » donne un aspect accompli</td>
                    <td><input type="radio" name="question-3" value="true" /></td>
                    <td><input type="radio" name="question-3" value="false" /></td>
                    <td className="result"></td>
                </tr>
                <tr className="question">
                    <td>L’aspect itératif dit la répétition d’une action</td>
                    <td><input type="radio" name="question-4" value="true" /></td>
                    <td><input type="radio" name="question-4" value="false" /></td>
                    <td className="result"></td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <input type="submit" value="Vérifier vos réponses" style={{ float: 'right', marginTop: '40px', fontSize: '1.3em' }} />
                    </td>
                </tr>
            </tfoot>
        </table>
    </form>

    <form id="quiz-4">
        <h3>Maintenant, veuillez répondre aux questions suivantes afin de passer à la partie prochaine :</h3>
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th>Vrai</th>
                    <th>Faux</th>
                    <th>Résultat</th>
                </tr>
            </thead>
            <tbody>
                <tr className="question">
                    <td>
                        <img src={AspectProgressifImg} style={{ width: '120px', height: '40px' }} alt="aspect-progressif" />
                        <span style={{ position: 'relative', top: '-12px', marginLeft: '5px' }}> est progressif</span>
                    </td>
                    <td><input type="radio" name="question-1" value="true" /></td>
                    <td><input type="radio" name="question-1" value="false" /></td>
                    <td className="result"></td>
                </tr>
                <tr className="question">
                    <td>
                        <img src={AspectAccompliImg} style={{ width: '120px', height: '40px' }} alt="aspect-accompli" />
                        <span style={{ position: 'relative', top: '-12px', marginLeft: '5px' }}> est accompli</span>
                    </td>
                    <td><input type="radio" name="question-2" value="true" /></td>
                    <td><input type="radio" name="question-2" value="false" /></td>
                    <td className="result"></td>
                </tr>
                <tr className="question">
                    <td>L’imparfait donne un aspect progressif</td>
                    <td><input type="radio" name="question-3" value="true" /></td>
                    <td><input type="radio" name="question-3" value="false" /></td>
                    <td className="result"></td>
                </tr>
                <tr className="question">
                    <td>Le passé composé donne un aspect accompli</td>
                    <td><input type="radio" name="question-4" value="true" /></td>
                    <td><input type="radio" name="question-4" value="false" /></td>
                    <td className="result"></td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <input type="submit" value="Vérifier vos réponses" style={{ float: 'right', marginTop: '40px', fontSize: '1.3em' }} />
                    </td>
                </tr>
            </tfoot>
        </table>
    </form>

    <form id="quiz-5">
        <h3>Maintenant, veuillez répondre aux questions suivantes afin de passer à la partie prochaine :</h3>
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th>Vrai</th>
                    <th>Faux</th>
                    <th>Résultat</th>
                </tr>
            </thead>
            <tbody>
                <tr className="question">
                    <td><em>J'ai mangé une pizza hier soir</em>, donne un aspect progressif</td>
                    <td><input type="radio" name="question-1" value="true" /></td>
                    <td><input type="radio" name="question-1" value="false" /></td>
                    <td className="result"></td>
                </tr>
                <tr className="question">
                    <td><em>Léa aimait Léo de tout son cœur</em>, donne un aspect progressif</td>
                    <td><input type="radio" name="question-2" value="true" /></td>
                    <td><input type="radio" name="question-2" value="false" /></td>
                    <td className="result"></td>
                </tr>
                <tr className="question">
                    <td><em>Je mangeais une pizza hier</em>, donne un aspect progressif</td>
                    <td><input type="radio" name="question-3" value="true" /></td>
                    <td><input type="radio" name="question-3" value="false" /></td>
                    <td className="result"></td>
                </tr>
                <tr className="question">
                    <td><em>Léa a aimé Léo</em>, donne un aspect accompli</td>
                    <td><input type="radio" name="question-4" value="true" /></td>
                    <td><input type="radio" name="question-4" value="false" /></td>
                    <td className="result"></td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <input type="submit" value="Vérifier vos réponses" style={{ float: 'right', marginTop: '40px', fontSize: '1.3em' }} />
                    </td>
                </tr>
            </tfoot>
        </table>
    </form>

</BlogLayout>
</>
}
