import React, { useState, useEffect } from "react";

import StartVideoPoster from "./StartVideoPoster";
import { MediaPlayerPlayIcon, MediaPlayerPauseIcon, MediaPlayerRewindIcon, MediaPlayerForwardIcon } from "./MediaPlayerIcons";

import PublitioVideo, { VideoPlayerConstructor as PublitioVideoPlayerConstructor } from "./VideoPlayerPublitio";
import VimeoVideo, { VideoPlayerConstructor as VimeoVideoPlayerConstructor } from "./VideoPlayerVimeo";

const videoHostingServices = {
  publitio: {
    Video: PublitioVideo,
    VideoPlayerConstructor: PublitioVideoPlayerConstructor,
  },
  vimeo: {
    Video: VimeoVideo,
    VideoPlayerConstructor: VimeoVideoPlayerConstructor,
  },
  wistia: {
  },
  youtube: {
  },
  amazon_s3: {
  },
  azure_blob: {
  },
  dailymotion: {
  },
  html5: {
  },
};

const themes = {
  pink: {
    primary: 'rgb(227, 146, 146)',
    secondary: '#fff',
  },
  green: {
    primary: '#7dcc79',
    secondary: '#fff',
  },
  cyan: {
    primary: '#6FBDC9',
    secondary: '#fff',
  },
  orange: {
    primary: '#FC953F',
    secondary: '#fff',
  },
}

export default ({ id, title, videoId, theme='orange', hostingService='publitio', style, ...props }) => {
  if (typeof window === `undefined`) {
    return null;
  }

  // TODO COULD THIS BE CREATED FROM THE REACT COMPONENT (AND A REFERENCE TO THE CONSTRUCTED VIDEO PLAYER OBJECT OBTAINED VIA A REF?)
  const videoPlayerId = `${id}-player`;

  //const [videoHostingService] = useState(videoHostingServices[hostingService]);
  const videoHostingService = videoHostingServices[hostingService];
  debugger;
  const Video = videoHostingService.Video;

  const [videoPlayer] = useState(new videoHostingService.VideoPlayerConstructor({
    videoPlayerId,
    videoId: videoId[hostingService],
    onReady: initVideoPlayer,
  }));

  const [playing, setPlaying] = useState(false);

  function control_video({ play, stop, rewind, forward }) {
    const videoContainer = document.querySelector(`#${id}`);
    let showStart = false;

    if (play !== undefined) {
      if (play) {
//! TEMP!!! null TO AVOID AUTOMATIC REWIND (should just unpause if was paused)
        videoPlayer.play(null);
      }
      else {
        videoPlayer.pause();
      }
    }
    else if (rewind || stop) {
      videoPlayer.pause();
      const animDuration = 500;
      setTimeout(() => videoPlayer.seekBeginning(), animDuration);
      showStart = true;
      play = false;
    }
    else if (forward) {
      videoPlayer.seekEnd();
    }

    setPlaying(play);

    videoContainer.classList.toggle('playing', play);

    videoContainer.classList.toggle('show-start', showStart);

    if (showStart) {
      const svg = videoContainer.querySelector('svg');
      svg.classList.remove('animated', 'reverse');
      requestAnimationFrame(() => svg.classList.add('animated'));
    }
    else {
      const svg = videoContainer.querySelector('svg');
      svg.classList.add('reverse');
      svg.classList.remove('animated');
      requestAnimationFrame(() => svg.classList.add('animated'));
    }
}

  function play_video(play = true) {
    control_video({ play: true });
  }

  function pause_video() {
    control_video({ play: false });
  }

  function stop_video() {
    control_video({ stop: true });
  }

  function rewind_video() {
    control_video({ rewind: true });
  }

  function forward_video() {
    control_video({ forward: true });
  }

  //! if (typeof window !== `undefined`) {
    useEffect(() => setTimeout(rewind_video), []);
  //! }






/*
setTimeout(function () {
        initVideoPlayer();
}, 500);
*/


//let videoPlayer = new VideoPlayer();




//---------------------------------------------------------------------------------------------------------------------

let videoSequence;
let clips;
let currClip;
let currClipIndex;

let currQuizIndex;

/**
 * 
 * @param {*} _videoSequence 
 */
function initVideoPlayer() {
  //! alert("hello from initVideoPlayer");
  const playVideoSequenceForm = document.querySelector('#play-video-sequence');

  if (playVideoSequenceForm) {
    //! const clips = document.querySelectorAll('[id^="imparfait-passe-compose-"');
    const videoSequence = {
      clips: [
        {
          video: {
            //document.querySelector('#imparfait-passe-compose-1'),
            source: 'l9ee_eN4AYI',
          },
          quizzes: [
            document.querySelector('#quiz-1'),
            document.querySelector('#quiz-2'),
          ],
        },
        {
          video: {
            //document.querySelector('#imparfait-passe-compose-2'),
            source: 'F4SNtiiKR-0',
          },
          quizzes: [
            document.querySelector('#quiz-3'),
            document.querySelector('#quiz-4'),
            document.querySelector('#quiz-5'),
          ],
        },
      ],
    };

    initVideoSequence(videoSequence);

    const playVideoSequenceSubmit = playVideoSequenceForm.querySelector('input[type="submit"]');
    playVideoSequenceSubmit.addEventListener('click', (event) => {
      event.preventDefault();
      playVideoSequenceForm.style.display = 'none';

      playClip(0);
    });
  }
}

/**
 * 
 * @param {*} _clips 
 * @param {*} _quizForms 
 */
function initVideoSequence(_videoSequence) {
  videoSequence = _videoSequence;
  clips = videoSequence.clips;
  initClip(0);

  // Just in case it starts up automatically (Chrome?)
  videoPlayer.stop();
}

/**
 * 
 * @param {int} clipIndex 
 */
function initClip(clipIndex) {
  currClipIndex = clipIndex;
  currClip = clips[clipIndex];

  // Hide all but currently active clip
  for (let clip of clips) {
    //! clip.video.style.display = clip == currClip ? 'inherit' : 'none';

    // Hide all clip's quiz forms
    for (let quizForm of clip.quizzes) {
        quizForm.style.display = 'none';
    }
  }

  videoPlayer.load(currClip.video);
}

/**
 * 
 * @param {int} clipIndex 
 */
function playClip(clipIndex) {
  // Detach current (to become previous) clip time update handler
  //! if (currClip) {
  //!  currClip.video.removeEventListener('timeupdate', clipTimeUpdateHandler);
  //! }

  initClip(clipIndex);

  currQuizIndex = 0;
  //! startTime = 0.0;
  //! stopTime = 6.0; // TODO GET FROM PARAMS FILE ???
  //! paused = false;
  //!currClip.video.play();
  videoPlayer.play(8, 15);
}

function clipTimeUpdateHandler(event) {
  //! if (this.currentTime >= stopTime && !paused) {
  //!  currClip.video.pause();
  //!  currClip.video.pause();
    videoPlayer.pause();
  //!  paused = true;

    // Activate quiz
    currClip.quizzes[currQuizIndex].style.display = 'inherit';
    const quizSubmit = currClip.quizzes[currQuizIndex].querySelector('input[type="submit"]');
    quizSubmit.addEventListener('click', submitQuizForm);
  //! }
}

//---------------------------------------------------------------------------------------------------------------------







return (
<div id={id} className="video-container"
  style={{ display: 'flex', justifyContent: 'center', position: 'relative', flexDirection: 'column', alignItems: 'center', ...style }}
>

<style dangerouslySetInnerHTML={{__html: `
  #${id}.show-start .title,
  #${id}.show-start button,
  #${id}.show-start .poster {
    opacity: 1;
  }

  #${id} .title,
  #${id} button,
  #${id} .poster {
    opacity: 0;
    transition: 1s all;
  }

  #${id}:not(.show-start) .title {
    top: -20px !important;
  }

  #${id}.show-start .video-player {
    opacity: 0;
  }

  #${id} .video-player {
    opacity: 1;
    transition: 1s all;
    /* Without this there is a slight (1 pixel) gap at the bottom of the video */
    object-fit: fill;
  }

  #${id} .poster svg,
  #${id} .video-player {
    border: 5px solid #ddd; /* ${themes[theme].primary} */
    border-radius: 6px;
    Xfilter: saturate(25%) brightness(1.2);
  }

  #${id} .poster svg {,
    box-shadow: inset 0px 0px 5px rgba(0, 0, 0, .25);
  }

  #${id} .btn svg,
  #${id} button {
    cursor: pointer;
    outline: none;
    transition: all 400ms cubic-bezier(.47, 1.64, .41, .8);
  }

  #${id} button {
    border-style: solid;
    border-color: #ccc;
  }

  #${id}.show-start button:hover {
    transform: scale(1.1);
  }

  #${id} .btn.animated svg:hover {
    transform: scale(1.1);
  }

  #${id} .start-lesson.btn {
    background: ${themes[theme].primary};
    filter: saturate(25%) brightness(1.2);
  }

  #${id}.show-start .media-player-buttons {
    display: none !important;
  }

  #${id} .media-player-buttons {
    opacity: 1;
    transition: opacity 400ms linear .25s;
  }

  #${id}.show-start .media-player-buttons,
  #${id}:not(:hover) .media-player-buttons {
    opacity: 0;
  }

  /* Background amorphous shape */
  #${id} #background-simple--inject-212 * {
    fill: #fff !important;
    Xfill: gold !important;
    fill: ${themes[theme].secondary} !important;
  }

  #${id} .heroicon-component-accent {
    fill: ${themes[theme].primary} !important;
  }
  
  #${id} #plants--inject-212 g *, /* Plants */
  #${id} #el3hy48rdnhsu, /* Neck shadow */
  #${id} #elchzinj05p2d, /* Timeline play button interior */
  #${id} #ellkh39txw2e9 { /* Big held play button */
    fill: ${themes[theme].primary} !important;
  }

  #${id} #plants--inject-212 g { /* Plants */
    opacity: 0.8 !important;
  }

  #${id} svg .animable {
    animation-duration: 2s !important;
  }

  #${id} svg.reverse .animable {
    animation-direction: reverse !important;
    animation-duration: .5s !important;
  }

  #${id} .title sup {
    text-transform: none;
    font-size: .75em;
  }

</style>
`}}></style>

  <Video player={videoPlayer} />

  <StartVideoPoster style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />

  <h2
    className="title"
    style={{
      position: 'absolute',
      top: '-8px',
      zIndex: 1000,
      padding: '5px 15px',
      color: '#333',
      fontFamily: 'arial',
      fontWeight: 'normal',
      fontSize: '1.4em',
      textAlign: 'center',
      textTransform: 'uppercase'
    }}
    dangerouslySetInnerHTML={{ __html: title }}
  />

  <button
    className="start-lesson btn animated"
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      position: 'absolute',
      bottom: '-15px',
      zIndex: 1000,
      fontSize: '1.1em',
      padding: '0px 15px',
      borderRadius: '20px',
      Xbackground: 'rgb(249, 233, 233)',
      boxShadow: '0px 0px 5px rgba(0, 0, 0, .25)'
    }}
    onClick={ play_video }>
    <span style={{ position: 'relative', top: '1px' }} >Commencer la leçon</span>
    <MediaPlayerPlayIcon size="30" style={{ position: 'relative', top: '3px', marginLeft: '10px' }} onClick={ play_video } />
  </button>

  {!false && <div class="media-player-buttons" style={{ display: 'flex', justifyContent: 'space-around', width: '40%', position: 'absolute', bottom: '-33px', zIndex: 1000 }}>
    {true && <MediaPlayerRewindIcon onClick={ rewind_video } />}
    {!playing && <MediaPlayerPlayIcon onClick={ play_video } />}
    {playing && <MediaPlayerPauseIcon onClick={ pause_video } />}
    {true && <MediaPlayerForwardIcon onClick={ forward_video } />}
  </div>}
</div>
);
}





/**
 * 
 * @param {*} event 
 */
// TEMP export submitQuizForm
export function submitQuizForm(event, currQuizIndex, currClip, currClipIndex) {
  event.preventDefault();

  const quizForm = currClip.quizzes[currQuizIndex];
  const questionRows = quizForm.querySelectorAll('tr.question');

  const numQuestions = questionRows.length;

  const answers = [];
  for (let index = 0; index < numQuestions; ++index) {
    const checkedCheckbox = quizForm.querySelector('input[name="question-' + (index + 1) + '"]:checked');
    if (!checkedCheckbox) {
      alert("Veuillez répondre à toutes les questions !");
      return;
    }
    answers.push(checkedCheckbox.value === 'true');
  }

  this.removeEventListener('click', submitQuizForm);

  // Clips answers
  const clipsAnswers = [
    [
      [false, true, true, false],
      [true, false, false, false],
    ],
    [
      [false, false, true, true],
      [true, true, true, true],
      [false, false, true, true],
    ],
  ];

  const clipAnswers = clipsAnswers[currClipIndex][currQuizIndex];

  let numCorrect = 0;
  for (let index = 0; index < numQuestions; ++index) {
    const correct = answers[index] == clipAnswers[index];
    if (correct) {
      numCorrect += 1;
    }
    const resultEl = quizForm.querySelector('.question:nth-child(' + (index + 1 )+ ')');
    resultEl.classList.remove('correct', 'incorrect');
    resultEl.classList.add(correct ? 'correct' : 'incorrect');
  }

  checkAnswers(numQuestions, numCorrect, currQuizIndex, currClip, currClipIndex);
}


// TEMP export checkAnswers
export function checkAnswers(videoPlayer, numQuestions, numCorrect, currQuizIndex, currClip, currClipIndex) {
  setTimeout(function () {
    if (numCorrect == numQuestions) {
      //alert("Très bien ! Nous allons passer à la partie suivante de la vidéo...")
  
      currClip.quizzes[currQuizIndex].style.display = 'none';
  
      if (currClipIndex == 0 && currQuizIndex == 0) {
        videoPlayer.play(60, 14);
        ++currQuizIndex;
      }
      else if (currClipIndex == 0 && currQuizIndex == 1) {
        // TEMP playClip(1);
      }
      else if (currClipIndex == 1 && currQuizIndex == 0) {
       videoPlayer.play(23, 15);
        ++currQuizIndex;
      }
      else if (currClipIndex == 1 && currQuizIndex == 1) {
        videoPlayer.play(40, 15);
        ++currQuizIndex;
      }
      else if (currClipIndex == 1 && currQuizIndex == 2) {
        alert("*** Bravo ! Vous arrivez à déverrouiller toutes les vidéos de cette leçon. ***");
        videoPlayer.stop();
      }
  
      const timelineItem = document.querySelector('#video-timeline .vcv-timeline-item:not(.vcv-step-done)');
      timelineItem.classList.add('vcv-step-done');
    }
    else {
      alert("Je vous invite de revoir la partie précédente de la vidéo afin de maîtriser les notions au mieux.")
  
      //! currClip.video.currentTime = startTime;
      currClip.quizzes[currQuizIndex].style.display = 'none';
  
        //! currClip.video.play();
        //! paused = false;
        videoPlayer.rewind();
    }
  }, 3000);
}
