import React from "react";

export const MediaPlayerPlayIcon = ({size = 50, ...props}) => <div {...props} className="btn animated" dangerouslySetInnerHTML={{ __html: `
<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 50 50" class="heroicon-media-buttons heroicon heroicons-lg">
    <path class="heroicon-media-buttons-play-circle heroicon-component-fill" d="M24 46a22 22 0 1 0 0-44 22 22 0 0 0 0 44z"></path>
    <polygon class="heroicon-media-buttons-play-symbol heroicon-component-accent heroicon-component-fill" points="17 15.144 17 14 18 14.556 33.97 23.428 35 24 33.97 24.572 18 33.444 17 34 17 32.856"></polygon>
    <path class="heroicon-shadows" d="
    M45.91 22a22 22 0 1 1-43.82 0 22 22 0 0 0 43.82 0z
    "></path>
    <path class="heroicon-outline" fill-rule="nonzero" d="
    M48 24a24 24 0 1 1-48 0 24 24 0 0 1 48 0z
    M24 46a22 22 0 1 0 0-44 22 22 0 0 0 0 44zm0-40v1A17 17 0 0 0 8.8 31.6l-.9.45A18 18 0 0 1 24 6zm-7 9.14V14l1 .56 15.97 8.87L35 24l-1.03.57L18 33.44 17 34V15.14z
    M32.94 24L18 15.7v16.6L32.94 24z
    "></path>
</svg>
`}}/>

export const MediaPlayerPauseIcon = (props) => <div {...props} className="btn animated"dangerouslySetInnerHTML={{ __html: `
<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="50 0 50 50" class="heroicon-media-buttons heroicon heroicons-lg">
<path class="heroicon-media-buttons-pause-circle heroicon-component-fill" d="M76 46a22 22 0 1 0 0-44 22 22 0 0 0 0 44z"></path>
<path class="heroicon-media-buttons-pause-symbol heroicon-component-accent heroicon-component-fill" d="M69 14h5v20h-6V14h1zm9 0h6v20h-6V14z"></path>

    <path class="heroicon-shadows" d="
    M98 22a22 22 0 1 1-43.82 0 22 22 0 0 0 43.82 0z
    "></path>

    <path class="heroicon-outline" fill-rule="nonzero" d="
    M100 24a24 24 0 1 1-48 0 24 24 0 0 1 48 0z
    M76 46a22 22 0 1 0 0-44 22 22 0 0 0 0 44z
    M58 24A18 18 0 0 1 76 6v1a17 17 0 0 0-15.2 24.6l-.9.45A17.93 17.93 0 0 1 58 24zm11-10h5v20h-6V14h1zm4 1h-4v18h4V15zm5-1h6v20h-6V14zm1 1v18h4V15h-4z

    "></path>
</svg>
`}}/>

export const MediaPlayerRewindIcon = (props) => <div {...props} className="btn animated"dangerouslySetInnerHTML={{ __html: `
<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 50 50 50" class="heroicon-media-buttons heroicon heroicons-lg">
    <path class="heroicon-media-buttons-back-circle heroicon-component-fill" d="M24 98a22 22 0 1 0 0-44 22 22 0 0 0 0 44z"></path>
    <path class="heroicon-media-buttons-back-symbol heroicon-component-accent heroicon-component-fill" d="M19 76.56V84h-4V68h4v8.56zm1.95.03L20 76l.95-.59L32 68.61l1-.61v16l-1-.62-11.05-6.8z"></path>
    <path class="heroicon-shadows" d="
    M45.91 74a22 22 0 1 1-43.82 0 22 22 0 0 0 43.82 0z
    "></path>
    <path class="heroicon-outline" fill-rule="nonzero" d="
    M48 76a24 24 0 1 1-48 0 24 24 0 0 1 48 0z
    M24 98a22 22 0 1 0 0-44 22 22 0 0 0 0 44z
    M6 76a18 18 0 0 1 18-18v1A17 17 0 0 0 8.8 83.6l-.9.45A17.93 17.93 0 0 1 6 76zm13 .56V84h-4V68h4v8.56z
    M18 69h-2v14h2V69zm2.95 7.59L20 76l.95-.59L32 68.61l1-.61v16l-1-.62-11.05-6.8zm.96-.59L32 82.21V69.79L21.9 76z
    "></path>
</svg>
`}}/>

export const MediaPlayerForwardIcon = (props) => <div {...props} className="btn animated"dangerouslySetInnerHTML={{ __html: `
<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="50 50 50 50" class="heroicon-media-buttons heroicon heroicons-lg">
    <path class="heroicon-media-buttons-forward-circle heroicon-component-fill" d="M98 76a22 22 0 1 1-44 0 22 22 0 0 1 44 0z"></path>
    <path class="heroicon-media-buttons-forward-symbol heroicon-component-accent heroicon-component-fill" d="M80 76l-.95.59L68 83.39 67 84V68l1 .62 11.05 6.8.95.58zm1 7V68h4v16h-4v-1z"></path>
    <path class="heroicon-shadows" d="
    M98 74a22 22 0 1 1-43.82 0 22 22 0 0 0 43.82 0z
    "></path>
    <path class="heroicon-outline" fill-rule="nonzero" d="
    M76 100a24 24 0 1 1 0-48 24 24 0 0 1 0 48zm22-24a22 22 0 1 0-44 0 22 22 0 0 0 44 0z
    M76 58v1a17 17 0 0 0-15.2 24.6l-.9.45A18 18 0 0 1 76 58zm4 18l-.95.59L68 83.39 67 84V68l1 .62 11.05 6.8.95.58zm-12-6.21v12.42L78.1 76 68 69.79z
    M81 83V68h4v16h-4v-1zm1 0h2V69h-2v14z
    "></path>
</svg>
`}}/>

export const MediaPlayerIcon = (props) => <div {...props} className="btn animated"dangerouslySetInnerHTML={{ __html: `
<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" class="heroicon-media-player heroicon heroicons-lg">
    <rect class="heroicon-media-player-chrome heroicon-component-accent heroicon-component-fill" width="100" height="80" y="10" rx="4"></rect>
    <rect class="heroicon-media-player-video heroicon-component-fill" width="92" height="64" x="4" y="14"></rect>
    <circle class="heroicon-media-player-play-circle heroicon-component-accent heroicon-component-fill" cx="50" cy="46" r="14"></circle>
    <polygon class="heroicon-media-player-play-symbol heroicon-component-fill" points="46 40 56 46 46 52"></polygon>
    <path class="heroicon-media-player-controls heroicon-component-fill" d="M4 80l6 3-6 3v-6zm8 1h66v4H12v-4zm68 0h4v4h-4v-4zm6 0h4v4h-4v-4zm6 0h4v4h-4v-4z"></path>
    <path class="heroicon-outline" fill-rule="nonzero" d="M0 14a4 4 0 0 1 4-4h92a4 4 0 0 1 4 4v72a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V14zm2 0v72c0 1.11.9 2 2 2h92a2 2 0 0 0 2-2V14a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2zm2 0h92v64H4V14zm1 1v62h90V15H5zm59 31a14 14 0 1 1-28 0 14 14 0 0 1 28 0zM50 58a12 12 0 1 0 0-24 12 12 0 0 0 0 24zM40 46a10 10 0 0 1 10-10v1a9 9 0 0 0-8.05 13.03l-.9.44A9.96 9.96 0 0 1 40 46zm6-6l1 .6 8.03 4.82.97.58-.97.58L47 51.4l-1 .6V40zm1 10.23L54.06 46 47 41.77v8.46zM12 81h66v4H12v-4zm1 1v2h64v-2H13zm68-1h3v4h-4v-4h1zm2 1h-2v2h2v-2zm3-1h4v4h-4v-4zm1 1v2h2v-2h-2zm6-1h3v4h-4v-4h1zm2 1h-2v2h2v-2zm-91-.88V80l1 .5 3.88 1.94L10 83l-1.12.56L5 85.5 4 86v-4.88zM7.76 83L5 81.62v2.76L7.76 83z"></path>
</svg>
`}}/>
