import React from "react";

export default () => (
<div className="poster"
dangerouslySetInnerHTML={{__html: `
<svg
style="z-index: 100; position: absolute; top: 0; left: 0; width: 100%; height: 100%; box-sizing: border-box; background: #fffa;
Xbackground: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255,255,255,.5) 50%, rgba(96,96,96,.6) 100%);
background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(255, 255, 255, 0.58) 60%, rgba(124, 115, 115, 0.92) 100%);
Xborder-radius: 6px;
box-shadow: inset 0px 0px 5px rgba(0, 0, 0, .25);
Xborder: 5px solid #ccc;"
class="animated" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="960" height="444">
<style>
svg:not(.animated) .animable {opacity: 0;}
.animated #background-simple--inject-212 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) slideDown;animation-delay: 0s;}
.animated #Graphics--inject-212 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) lightSpeedRight;animation-delay: 0s;}
.animated #Plants--inject-212 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) lightSpeedLeft;animation-delay: 0s;}
.animated #Character--inject-212 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) slideUp;animation-delay: 0s;}
.animated #progess-bar--inject-212 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) slideLeft;animation-delay: 0s;}
            @keyframes slideDown {                0% {                    opacity: 0;                    transform: translateY(-30px);                }                100% {                    opacity: 1;                    transform: translateY(0);                }            }                    @keyframes lightSpeedRight {              from {                transform: translate3d(50%, 0, 0) skewX(-20deg);                opacity: 0;              }              60% {                transform: skewX(10deg);                opacity: 1;              }              80% {                transform: skewX(-2deg);              }              to {                opacity: 1;                transform: translate3d(0, 0, 0);              }            }                    @keyframes lightSpeedLeft {              from {                transform: translate3d(-50%, 0, 0) skewX(20deg);                opacity: 0;              }              60% {                transform: skewX(-10deg);                opacity: 1;              }              80% {                transform: skewX(2deg);              }              to {                opacity: 1;                transform: translate3d(0, 0, 0);              }            }                    @keyframes slideUp {                0% {                    opacity: 0;                    transform: translateY(30px);                }                100% {                    opacity: 1;                    transform: inherit;                }            }                    @keyframes slideLeft {                0% {                    opacity: 0;                    transform: translateX(-30px);                }                100% {                    opacity: 1;                    transform: translateX(0);                }            }
</style>
<g id="background-simple--inject-212" class="animable"
style="transform-origin: 234.587px 237.671px;"><g id="background-simple--inject-212" class="animable"
style="transform-origin: 234.587px 237.671px;"><path d="M226.05,400c60.89,9.52,123.91-15.29,157.66-67.43a182.9,182.9,0,0,0,16.2-31.48c13.33-33.4,19.6-72.3,15.9-108.14C411.89,155,398.06,113.64,365.46,91,335,69.86,296.6,69.91,262,79.63c-26.27,7.39-49.2,20.93-71.2,36.74-11.36,8.17-22.56,16.62-34.55,23.87s-25.13,11.95-37.72,18c-23.18,11.15-44.21,27-56.73,49.95C47.67,234.08,50.38,266.86,63,292.76,92,352,163.16,390.15,226.05,400Z"
style="fill: rgb(227, 146, 146); transform-origin: 234.587px 237.671px;" id="eln2ob2acigk" class="animable"></path><g id="elseq7ukfkr6a"><path d="M226.05,400c60.89,9.52,123.91-15.29,157.66-67.44a182.44,182.44,0,0,0,16.2-31.47c13.33-33.41,19.6-72.3,15.9-108.15C411.89,155,398.06,113.68,365.46,91,335,69.9,296.6,70,262,79.67c-26.27,7.39-49.2,20.93-71.2,36.74-11.36,8.17-22.56,16.62-34.55,23.87s-25.13,11.94-37.72,18c-23.18,11.15-44.21,27-56.73,49.94C47.67,234.12,50.38,266.89,63,292.8,92,352.07,163.16,390.18,226.05,400Z"
style="fill: rgb(255, 255, 255); opacity: 0.8; transform-origin: 234.587px 237.701px;" class="animable"></path></g></g></g><g id="Graphics--inject-212" class="animable"
style="transform-origin: 244.705px 167.475px;"><g id="background-details--inject-212" class="animable"
style="transform-origin: 244.705px 167.475px;"><path d="M427.86,228.55H276.19a.5.5,0,0,1-.5-.5V123.5a.5.5,0,0,1,.5-.5H427.86a.5.5,0,0,1,.5.5V228.05A.5.5,0,0,1,427.86,228.55Zm-151.17-1H427.36V124H276.69Z"
style="fill: rgb(38, 50, 56); transform-origin: 352.025px 175.775px;" id="elbo5ym5sw9k" class="animable"></path><path d="M413.08,138.57a3.88,3.88,0,1,1,3.88-3.88A3.88,3.88,0,0,1,413.08,138.57Zm0-6.76a2.88,2.88,0,1,0,2.88,2.88A2.88,2.88,0,0,0,413.08,131.81Z"
style="fill: rgb(38, 50, 56); transform-origin: 413.08px 134.69px;" id="eluesc4r3ddn" class="animable"></path><path d="M404.34,134.69a3.38,3.38,0,1,1-3.38-3.38A3.38,3.38,0,0,1,404.34,134.69Z"
style="fill: rgb(38, 50, 56); transform-origin: 400.96px 134.69px;" id="elusgabhjdxxb" class="animable"></path><path d="M392.22,134.69a3.38,3.38,0,1,1-3.38-3.38A3.38,3.38,0,0,1,392.22,134.69Z"
style="fill: rgb(38, 50, 56); transform-origin: 388.84px 134.69px;" id="elgy6ft7db81j" class="animable"></path><rect x="286.82" y="146.56" width="131.18" height="72.9"
style="fill: rgb(38, 50, 56); transform-origin: 352.41px 183.01px;" id="elck3v95xjh2b" class="animable"></rect><path d="M359.33,206.14h-.15a26.54,26.54,0,0,1,.14-53.07h.16a26.54,26.54,0,0,1-.15,53.08Zm0-52.08a25.54,25.54,0,0,0-.14,51.07h.15a25.54,25.54,0,0,0,.14-51.08Z"
style="fill: rgb(255, 255, 255); transform-origin: 359.578px 179.61px;" id="elnfqsfi0jp6c" class="animable"></path><polygon points="353.88 167.89 353.75 192.21 371.58 180.06 353.88 167.89"
style="fill: rgb(255, 255, 255); transform-origin: 362.665px 180.05px;" id="elrb12dc8vv1j" class="animable"></polygon><g id="background-details--inject-212" class="animable"
style="transform-origin: 130.985px 121.875px;"><path d="M196.11,130.49H72.93a.5.5,0,0,1-.5-.5V111.34a.5.5,0,0,1,.5-.5H196.11a.51.51,0,0,1,.5.5V130A.5.5,0,0,1,196.11,130.49Zm-122.68-1H195.61V111.84H73.43Z"
style="fill: rgb(38, 50, 56); transform-origin: 134.52px 120.665px;" id="el30zq0705oei" class="animable"></path><path d="M87.79,120.67a3.58,3.58,0,1,1-3.57-3.57A3.57,3.57,0,0,1,87.79,120.67Z"
style="fill: rgb(38, 50, 56); transform-origin: 84.21px 120.68px;" id="el7vr05i2k9gg" class="animable"></path><path d="M103.58,120.67A3.57,3.57,0,1,1,100,117.1,3.57,3.57,0,0,1,103.58,120.67Z"
style="fill: rgb(38, 50, 56); transform-origin: 100.01px 120.67px;" id="elwpi0qn21z4" class="animable"></path><path d="M119.38,120.67a3.58,3.58,0,1,1-3.57-3.57A3.57,3.57,0,0,1,119.38,120.67Z"
style="fill: rgb(38, 50, 56); transform-origin: 115.8px 120.68px;" id="elbf1sy0m9y55" class="animable"></path><path d="M200.42,107.4h-8.35a.5.5,0,0,1,0-1h8.35a.5.5,0,0,1,0,1Z"
style="fill: rgb(38, 50, 56); transform-origin: 196.245px 106.9px;" id="elw8plbecpmei" class="animable"></path><path d="M93.77,137.35H61.55a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H93.77a.5.5,0,0,1,.5.5A.5.5,0,0,1,93.77,137.35Z"
style="fill: rgb(38, 50, 56); transform-origin: 77.66px 136.85px;" id="elvpo1vj3trv" class="animable"></path></g></g></g><g id="Plants--inject-212" class="animable"
style="transform-origin: 250.004px 333.221px;"><g id="plants--inject-212" class="animable"
style="transform-origin: 250.004px 333.221px;"><path d="M403.59,414.51a.49.49,0,0,1-.47-.32c-6.5-17.67,2.86-38.34,9-49.08,2.24-3.91,4.61-7.76,6.9-11.49,6.82-11.1,13.26-21.58,17.15-34.11,4.94-15.88,6.36-35,4.22-56.75a.5.5,0,0,1,.45-.54.51.51,0,0,1,.55.45c2.14,21.91.71,41.13-4.26,57.13-3.94,12.66-10.41,23.19-17.26,34.34-2.28,3.72-4.64,7.57-6.88,11.47-6.07,10.59-15.32,30.94-8.95,48.23a.5.5,0,0,1-.3.64Z"
style="fill: rgb(227, 146, 146); transform-origin: 421.635px 338.364px;" id="elwdph4hxy03" class="animable"></path><path d="M401.41,407s7.48-20.83,16.77-18.66-16,20.7-16,20.7Z"
style="fill: rgb(227, 146, 146); transform-origin: 410.82px 398.611px;" id="elsn4x01ps0fk" class="animable"></path><path d="M404.94,380.92c-.11-.66,1.28-21.8-5.81-21.1s1.28,22.88,4.42,24.51Z"
style="fill: rgb(227, 146, 146); transform-origin: 400.67px 372.067px;" id="elmdzf2howq3a" class="animable"></path><path d="M400.67,403.12s-5.43-18.05-11.13-16.69S402,407.85,402,407.85Z"
style="fill: rgb(227, 146, 146); transform-origin: 395.216px 397.103px;" id="el47ygz8q538" class="animable"></path><path d="M403.92,385.14s21.73-9.26,21.61-16.34-20.55,13-20.55,13Z"
style="fill: rgb(227, 146, 146); transform-origin: 414.725px 376.214px;" id="elwrqlc7ajd8" class="animable"></path><path d="M410.82,368.54s-6.56-22.52-.77-25.48,2.53,22.3,2.53,22.3Z"
style="fill: rgb(227, 146, 146); transform-origin: 410.578px 355.679px;" id="elg2x9c0dowet" class="animable"></path><path d="M410.82,368.54s24.16-11.66,22.63-17.93-20,13.52-20,13.52Z"
style="fill: rgb(227, 146, 146); transform-origin: 422.17px 358.961px;" id="ell4zu6fv8x1l" class="animable"></path><path d="M422.17,349.47s-7.12-20.22-2.46-23.32,4.78,19.45,4.78,19.45Z"
style="fill: rgb(227, 146, 146); transform-origin: 421.309px 337.664px;" id="el1zkwewwttzo" class="animable"></path><path d="M422.17,349.47s18.1-7.55,17.71-14.14-15.39,10.27-15.39,10.27Z"
style="fill: rgb(227, 146, 146); transform-origin: 431.028px 341.636px;" id="elfe7b332d1wp" class="animable"></path><path d="M430.92,333.87s-6.7-16.35-3.65-19.63,5,16.74,5,16.74Z"
style="fill: rgb(227, 146, 146); transform-origin: 429.372px 323.873px;" id="elta2kb0lgrl" class="animable"></path><path d="M432.29,330.28s10-16.84,13.91-14.49c5.27,3.14-14.48,16.39-14.48,16.39Z"
style="fill: rgb(227, 146, 146); transform-origin: 439.407px 323.873px;" id="eljwztw27nj7" class="animable"></path><path d="M437.49,316.85s-9.58-16.14-5.77-18.79,6.75,15,6.75,15Z"
style="fill: rgb(227, 146, 146); transform-origin: 434.647px 307.319px;" id="el5fkkkgv6x4t" class="animable"></path><path d="M438.75,311.84c.09-.42,7.08-19.51,12.37-18.19s-13.38,20.23-13.38,20.23Z"
style="fill: rgb(227, 146, 146); transform-origin: 444.902px 303.732px;" id="elf537q11fo47" class="animable"></path><path d="M441,297.79s-2.47-15.75-7.51-15.16S441,300.41,441,300.41Z" 
style="fill: rgb(227, 146, 146); transform-origin: 436.648px 291.512px;" id="el90n9jz7vx84" class="animable"></path><path d="M441.56,291.51s4.41-15.94,9.71-15.79-10,19.4-10,19.4Z"
style="fill: rgb(227, 146, 146); transform-origin: 446.828px 285.419px;" id="elxwwxezqtki" class="animable"></path><path d="M441.86,278.36s-5.28-15.36-10.14-13.05,10.14,17.3,10.14,17.3Z"
style="fill: rgb(227, 146, 146); transform-origin: 436.305px 273.842px;" id="elkajphkd721n" class="animable"></path><path d="M441.56,271.17s-5.32-18.63-.53-20.05S441.56,271.17,441.56,271.17Z"
style="fill: rgb(227, 146, 146); transform-origin: 441.168px 261.111px;" id="elonzsd7tt8ur" class="animable"></path><g id="el2ln34360wje"><g
style="opacity: 0.3; transform-origin: 420.409px 332.781px;" class="animable"><path d="M403.59,414.51a.49.49,0,0,1-.47-.32c-6.5-17.67,2.86-38.34,9-49.08,2.24-3.91,4.61-7.76,6.9-11.49,6.82-11.1,13.26-21.58,17.15-34.11,4.94-15.88,6.36-35,4.22-56.75a.5.5,0,0,1,.45-.54.51.51,0,0,1,.55.45c2.14,21.91.71,41.13-4.26,57.13-3.94,12.66-10.41,23.19-17.26,34.34-2.28,3.72-4.64,7.57-6.88,11.47-6.07,10.59-15.32,30.94-8.95,48.23a.5.5,0,0,1-.3.64Z"
style="fill: rgb(255, 255, 255); transform-origin: 421.635px 338.364px;" id="elbrhrmaa649q" class="animable"></path><path d="M401.41,407s7.48-20.83,16.77-18.66-16,20.7-16,20.7Z"
style="fill: rgb(255, 255, 255); transform-origin: 410.82px 398.611px;" id="elks95ib5uktg" class="animable"></path><path d="M404.94,380.92c-.11-.66,1.28-21.8-5.81-21.1s1.28,22.88,4.42,24.51Z"
style="fill: rgb(255, 255, 255); transform-origin: 400.67px 372.067px;" id="elk9pwiiskgl" class="animable"></path><path d="M400.67,403.12s-5.43-18.05-11.13-16.69S402,407.85,402,407.85Z"
style="fill: rgb(255, 255, 255); transform-origin: 395.216px 397.103px;" id="elr9l761md32f" class="animable"></path><path d="M403.92,385.14s21.73-9.26,21.61-16.34-20.55,13-20.55,13Z"
style="fill: rgb(255, 255, 255); transform-origin: 414.725px 376.214px;" id="elsh8ej885g8p" class="animable"></path><path d="M410.82,368.54s-6.56-22.52-.77-25.48,2.53,22.3,2.53,22.3Z"
style="fill: rgb(255, 255, 255); transform-origin: 410.578px 355.679px;" id="eldf7il324ryo" class="animable"></path><path d="M410.82,368.54s24.16-11.66,22.63-17.93-20,13.52-20,13.52Z"
style="fill: rgb(255, 255, 255); transform-origin: 422.17px 358.961px;" id="el2jftd5ya9qw" class="animable"></path><path d="M422.17,349.47s-7.12-20.22-2.46-23.32,4.78,19.45,4.78,19.45Z"
style="fill: rgb(255, 255, 255); transform-origin: 421.309px 337.664px;" id="eluq7iw92p7v" class="animable"></path><path d="M422.17,349.47s18.1-7.55,17.71-14.14-15.39,10.27-15.39,10.27Z"
style="fill: rgb(255, 255, 255); transform-origin: 431.028px 341.636px;" id="elg45t6m6covm" class="animable"></path><path d="M430.92,333.87s-6.7-16.35-3.65-19.63,5,16.74,5,16.74Z"
style="fill: rgb(255, 255, 255); transform-origin: 429.372px 323.873px;" id="elwonflednlcb" class="animable"></path><path d="M432.29,330.28s10-16.84,13.91-14.49c5.27,3.14-14.48,16.39-14.48,16.39Z"
style="fill: rgb(255, 255, 255); transform-origin: 439.407px 323.873px;" id="elycjdrpmy1m" class="animable"></path><path d="M437.49,316.85s-9.58-16.14-5.77-18.79,6.75,15,6.75,15Z"
style="fill: rgb(255, 255, 255); transform-origin: 434.647px 307.319px;" id="eluahskcjo84l" class="animable"></path><path d="M438.75,311.84c.09-.42,7.08-19.51,12.37-18.19s-13.38,20.23-13.38,20.23Z"
style="fill: rgb(255, 255, 255); transform-origin: 444.902px 303.732px;" id="elvg5zilxaicf" class="animable"></path><path d="M441,297.79s-2.47-15.75-7.51-15.16S441,300.41,441,300.41Z"
style="fill: rgb(255, 255, 255); transform-origin: 436.648px 291.512px;" id="eljj3dfm3htp" class="animable"></path><path d="M441.56,291.51s4.41-15.94,9.71-15.79-10,19.4-10,19.4Z"
style="fill: rgb(255, 255, 255); transform-origin: 446.828px 285.419px;" id="el815yd69fwud" class="animable"></path><path d="M441.86,278.36s-5.28-15.36-10.14-13.05,10.14,17.3,10.14,17.3Z"
style="fill: rgb(255, 255, 255); transform-origin: 436.305px 273.842px;" id="el27klgk1qdt1" class="animable"></path><path d="M441.56,271.17s-5.32-18.63-.53-20.05S441.56,271.17,441.56,271.17Z"
style="fill: rgb(255, 255, 255); transform-origin: 441.168px 261.111px;" id="elvn8jf9ao03" class="animable"></path></g></g><path d="M157,415.22a.51.51,0,0,1-.48-.34,60.29,60.29,0,0,1,7.25-53.54.51.51,0,0,1,.7-.12.5.5,0,0,1,.13.69,59.23,59.23,0,0,0-7.13,52.64.51.51,0,0,1-.31.64A.45.45,0,0,1,157,415.22Z"
style="fill: rgb(227, 146, 146); transform-origin: 158.976px 388.175px;" id="elfhgmyik4lkh" class="animable"></path><path d="M156.35,412.87s12.62-13.58,8.37-16.82S156.35,412.87,156.35,412.87Z"
style="fill: rgb(227, 146, 146); transform-origin: 160.972px 404.283px;" id="ellb5xei05td" class="animable"></path><path d="M154.72,406.29s-5.11-18.74-10.11-14.74S154.72,406.29,154.72,406.29Z"
style="fill: rgb(227, 146, 146); transform-origin: 149.158px 398.64px;" id="eliv882d98hik" class="animable"></path><path d="M153.73,397.67s12.12-11.24,9.25-14.74S154.73,392.3,153.73,397.67Z"
style="fill: rgb(227, 146, 146); transform-origin: 158.575px 390.003px;" id="elw6kbp5a9k8" class="animable"></path><path d="M154.17,388.17s1.91-20.6-5-19.61S154.17,388.17,154.17,388.17Z"
style="fill: rgb(227, 146, 146); transform-origin: 150.713px 378.348px;" id="el7juv1seikf9" class="animable"></path><path d="M158,373.06s15.2-12.86,10.33-16.24S158,373.06,158,373.06Z"
style="fill: rgb(227, 146, 146); transform-origin: 163.646px 364.744px;" id="el1sujypvanlvj" class="animable"></path><g id="elzu8lxnypew"><g
style="opacity: 0.3; transform-origin: 156.434px 385.824px;" class="animable"><path d="M156.94,415.22a.5.5,0,0,1-.47-.34,60.29,60.29,0,0,1,7.25-53.54.51.51,0,0,1,.7-.12.49.49,0,0,1,.12.69,59.26,59.26,0,0,0-7.12,52.64.51.51,0,0,1-.31.64A.47.47,0,0,1,156.94,415.22Z"
style="fill: rgb(255, 255, 255); transform-origin: 158.924px 388.175px;" id="elyic0k68l57" class="animable"></path><path d="M156.33,412.87s12.62-13.58,8.37-16.82S156.33,412.87,156.33,412.87Z"
style="fill: rgb(255, 255, 255); transform-origin: 160.952px 404.283px;" id="elz40j19q8fg" class="animable"></path><path d="M154.7,406.29s-5.11-18.74-10.11-14.74S154.7,406.29,154.7,406.29Z"
style="fill: rgb(255, 255, 255); transform-origin: 149.138px 398.64px;" id="elkgdux1sni8a" class="animable"></path><path d="M153.71,397.67s12.12-11.24,9.24-14.74S154.71,392.3,153.71,397.67Z"
style="fill: rgb(255, 255, 255); transform-origin: 158.551px 390.003px;" id="elf488imfcre" class="animable"></path><path d="M154.15,388.17s1.91-20.6-5-19.61S154.15,388.17,154.15,388.17Z"
style="fill: rgb(255, 255, 255); transform-origin: 150.693px 378.348px;" id="el9o41le7laqf" class="animable"></path><path d="M158,373.06s15.2-12.86,10.33-16.24S158,373.06,158,373.06Z"
style="fill: rgb(255, 255, 255); transform-origin: 163.646px 364.744px;" id="el5b3n5a0uljj" class="animable"></path></g></g><path d="M133.37,414.56a.51.51,0,0,1-.48-.36c-4.7-16.11-10-34.36-22.68-47.21-5.27-5.36-11.69-9.59-17.9-13.68a129.86,129.86,0,0,1-15.54-11.4A65.37,65.37,0,0,1,56,302.72a.5.5,0,1,1,1-.13,64.36,64.36,0,0,0,20.41,38.58,129.44,129.44,0,0,0,15.42,11.3c6.25,4.12,12.72,8.39,18.06,13.82,12.82,13,18.19,31.41,22.93,47.63a.5.5,0,0,1-.34.62Z"
style="fill: rgb(227, 146, 146); transform-origin: 94.9178px 358.355px;" id="elr84bzu4tepe" class="animable"></path><path d="M132.18,410s-9.12-18.05-16-13S132.18,410,132.18,410Z"
style="fill: rgb(227, 146, 146); transform-origin: 123.535px 403.054px;" id="elpgvkadl3ddf" class="animable"></path><path d="M127.51,395.6c-.12-.32-3-17.82,4.67-19.91S127.51,395.6,127.51,395.6Z"
style="fill: rgb(227, 146, 146); transform-origin: 130.787px 385.575px;" id="el4zt6i2uhvoe" class="animable"></path><path d="M122.71,384.24s-16.54-15-21.14-9.53S122.71,384.24,122.71,384.24Z"
style="fill: rgb(227, 146, 146); transform-origin: 111.865px 378.875px;" id="el391jdw3sq2u" class="animable"></path><path d="M116.7,374s-11-20.67-3.41-23S116.7,374,116.7,374Z"
style="fill: rgb(227, 146, 146); transform-origin: 114.234px 362.424px;" id="elh4qar3qv8w" class="animable"></path><path d="M110,365.35s-21.17-13.08-22.74-7.28S110,365.35,110,365.35Z"
style="fill: rgb(227, 146, 146); transform-origin: 98.5936px 360.968px;" id="elynej31l0na" class="animable"></path><path d="M100.65,358.36s-13-19-8-21.12C100.65,333.9,100.65,358.36,100.65,358.36Z"
style="fill: rgb(227, 146, 146); transform-origin: 96.0777px 347.643px;" id="el5l8ah41bosv" class="animable"></path><path d="M87.88,349.76s-20.38-12.07-24.42-6.3S87.88,349.76,87.88,349.76Z"
style="fill: rgb(227, 146, 146); transform-origin: 75.4736px 345.829px;" id="elrwroimyjf4" class="animable"></path><path d="M77.1,341.54s-10.26-21.22-4.71-22.2C81,317.82,77.1,341.54,77.1,341.54Z"
style="fill: rgb(227, 146, 146); transform-origin: 74.3623px 330.405px;" id="elqfywle6bh7" class="animable"></path><path d="M68.75,332.36S52,313,48.05,319.27,68.75,332.36,68.75,332.36Z"
style="fill: rgb(227, 146, 146); transform-origin: 58.1857px 325.19px;" id="ellc2dotzxnes" class="animable"></path><path d="M60.1,316.6s-10-19.86-3.57-21.53S60.1,316.6,60.1,316.6Z"
style="fill: rgb(227, 146, 146); transform-origin: 57.5538px 305.792px;" id="ele6xq427nsld" class="animable"></path><g id="eljyzy2tpv7vb"><g
style="opacity: 0.3; transform-origin: 91.1568px 354.772px;" class="animable"><path d="M133.37,414.56a.51.51,0,0,1-.48-.36c-4.7-16.11-10-34.36-22.68-47.21-5.27-5.36-11.69-9.59-17.9-13.68a129.86,129.86,0,0,1-15.54-11.4A65.37,65.37,0,0,1,56,302.72a.5.5,0,1,1,1-.13,64.36,64.36,0,0,0,20.41,38.58,129.44,129.44,0,0,0,15.42,11.3c6.25,4.12,12.72,8.39,18.06,13.82,12.82,13,18.19,31.41,22.93,47.63a.5.5,0,0,1-.34.62Z"
style="fill: rgb(255, 255, 255); transform-origin: 94.9178px 358.355px;" id="elv2c6qz7xl3p" class="animable"></path><path d="M132.18,410s-9.12-18.05-16-13S132.18,410,132.18,410Z"
style="fill: rgb(255, 255, 255); transform-origin: 123.535px 403.054px;" id="el9o1qkhwzwdj" class="animable"></path><path d="M127.51,395.6c-.12-.32-3-17.82,4.67-19.91S127.51,395.6,127.51,395.6Z"
style="fill: rgb(255, 255, 255); transform-origin: 130.787px 385.575px;" id="eldse56qd8ro7" class="animable"></path><path d="M122.71,384.24s-16.54-15-21.14-9.53S122.71,384.24,122.71,384.24Z"
style="fill: rgb(255, 255, 255); transform-origin: 111.865px 378.875px;" id="el59sf81t7zas" class="animable"></path><path d="M116.7,374s-11-20.67-3.41-23S116.7,374,116.7,374Z"
style="fill: rgb(255, 255, 255); transform-origin: 114.234px 362.424px;" id="eljz79mv4svd" class="animable"></path><path d="M110,365.35s-21.17-13.08-22.74-7.28S110,365.35,110,365.35Z"
style="fill: rgb(255, 255, 255); transform-origin: 98.5936px 360.968px;" id="elgsdmeq21q3" class="animable"></path><path d="M100.65,358.36s-13-19-8-21.12C100.65,333.9,100.65,358.36,100.65,358.36Z"
style="fill: rgb(255, 255, 255); transform-origin: 96.0777px 347.643px;" id="el41w8n08xq2k" class="animable"></path><path d="M87.88,349.76s-20.38-12.07-24.42-6.3S87.88,349.76,87.88,349.76Z"
style="fill: rgb(255, 255, 255); transform-origin: 75.4736px 345.829px;" id="eleujbck6owkj" class="animable"></path><path d="M77.1,341.54s-10.26-21.22-4.71-22.2C81,317.82,77.1,341.54,77.1,341.54Z"
style="fill: rgb(255, 255, 255); transform-origin: 74.3623px 330.405px;" id="el60p612paugv" class="animable"></path><path d="M68.75,332.36S52,313,48.05,319.27,68.75,332.36,68.75,332.36Z"
style="fill: rgb(255, 255, 255); transform-origin: 58.1857px 325.19px;" id="el24hfuxdlyis" class="animable"></path><path d="M60.1,316.6s-10-19.86-3.57-21.53S60.1,316.6,60.1,316.6Z"
style="fill: rgb(255, 255, 255); transform-origin: 57.5538px 305.792px;" id="el8jqx9hfqrqs" class="animable"></path></g></g><path d="M375.83,415.39a.51.51,0,0,1-.4-.2c-22.44-29,1.86-69.65,2.11-70.05a.5.5,0,1,1,.86.52c-.25.4-24.2,40.44-2.18,68.92a.5.5,0,0,1-.09.7A.48.48,0,0,1,375.83,415.39Z"
style="fill: rgb(227, 146, 146); transform-origin: 372.2px 380.142px;" id="el4tsm1ylo8re" class="animable"></path><path d="M370.43,405.89s-13.35-17.47-18.12-14.64,9.48,14.2,19.18,16.83Z"
style="fill: rgb(227, 146, 146); transform-origin: 361.425px 399.51px;" id="eloqy022g0vkn" class="animable"></path><path d="M367.75,398c0-.38,3.12-21.3,8.4-19.62s-7.73,22.11-7.73,22.11Z"
style="fill: rgb(227, 146, 146); transform-origin: 372.58px 389.387px;" id="el8zmas6r25gk" class="animable"></path><path d="M366.36,388.23S353.07,371.64,356,367s10.34,18.22,10.34,18.22Z"
style="fill: rgb(227, 146, 146); transform-origin: 360.969px 377.304px;" id="elv0s3w1eehc" class="animable"></path><path d="M367.15,378.27s14.51-10.23,11.67-13.73-11.67,11.32-11.67,11.32Z"
style="fill: rgb(227, 146, 146); transform-origin: 373.169px 371.138px;" id="eluu1ofi10ag7" class="animable"></path><path d="M369,367.11s-6.92-18.55-2.65-22.3,3.77,18.53,3.77,18.53Z"
style="fill: rgb(227, 146, 146); transform-origin: 367.548px 355.747px;" id="eldg3vwiwbng" class="animable"></path><path d="M373.17,355.75S390,339.8,386.1,336.62,374.69,349.62,373.17,355.75Z"
style="fill: rgb(227, 146, 146); transform-origin: 379.928px 345.98px;" id="elljd0labpwyj" class="animable"></path><g id="el4biiadmgf85"><g
style="opacity: 0.3; transform-origin: 369.023px 375.8px;" class="animable"><path d="M375.83,415.39a.51.51,0,0,1-.4-.2c-22.44-29,1.86-69.65,2.11-70.05a.5.5,0,1,1,.86.52c-.25.4-24.2,40.44-2.18,68.92a.5.5,0,0,1-.09.7A.48.48,0,0,1,375.83,415.39Z"
style="fill: rgb(255, 255, 255); transform-origin: 372.2px 380.142px;" id="el302pah8eehq" class="animable"></path><path d="M370.43,405.89s-13.35-17.47-18.12-14.64,9.48,14.2,19.18,16.83Z"
style="fill: rgb(255, 255, 255); transform-origin: 361.425px 399.51px;" id="el78gayegc2t7" class="animable"></path><path d="M367.75,398c0-.38,3.12-21.3,8.4-19.62s-7.73,22.11-7.73,22.11Z"
style="fill: rgb(255, 255, 255); transform-origin: 372.58px 389.387px;" id="eletz6sieze1b" class="animable"></path><path d="M366.36,388.23S353.07,371.64,356,367s10.34,18.22,10.34,18.22Z"
style="fill: rgb(255, 255, 255); transform-origin: 360.969px 377.304px;" id="ellr3ntyyrg9b" class="animable"></path><path d="M367.15,378.27s14.51-10.23,11.67-13.73-11.67,11.32-11.67,11.32Z"
style="fill: rgb(255, 255, 255); transform-origin: 373.169px 371.138px;" id="el988ayxpc3f6" class="animable"></path><path d="M369,367.11s-6.92-18.55-2.65-22.3,3.77,18.53,3.77,18.53Z"
style="fill: rgb(255, 255, 255); transform-origin: 367.548px 355.747px;" id="ella5fd3o6qa" class="animable"></path><path d="M373.17,355.75S390,339.8,386.1,336.62,374.69,349.62,373.17,355.75Z"
style="fill: rgb(255, 255, 255); transform-origin: 379.928px 345.98px;" id="elvbhw3mnsfb" class="animable"></path></g></g></g></g><g id="Character--inject-212" class="animable"
style="transform-origin: 230.932px 257.329px;"><g id="character--inject-212" class="animable"
style="transform-origin: 230.932px 257.329px;"><path d="M308.5,412.8c-1.08-19.23-1.82-32.55-1.82-32.55-68.74,16.65-105.94-10.08-105.94-10.08s-3,18.93-6.82,42.63Z"
style="fill: rgb(38, 50, 56); transform-origin: 251.21px 391.485px;" id="elny48cy0rw8" class="animable"></path><path d="M308.5,413.3H193.92a.49.49,0,0,1-.38-.18.51.51,0,0,1-.11-.4c3.81-23.7,6.82-42.63,6.82-42.63a.51.51,0,0,1,.3-.38.49.49,0,0,1,.48.05c.38.27,37.83,26.4,105.53,10a.49.49,0,0,1,.41.08.48.48,0,0,1,.2.38s.74,13.31,1.83,32.54a.51.51,0,0,1-.5.53Zm-114-1H308c-.93-16.58-1.61-28.66-1.76-31.42-62.89,15-99.78-6.46-105.1-9.86C200.52,374.75,197.83,391.66,194.51,412.3Z"
style="fill: rgb(38, 50, 56); transform-origin: 251.212px 391.485px;" id="eluod4m8o7bf" class="animable"></path><g id="eljsw4gabqi3t"><g
style="opacity: 0.6; transform-origin: 251.212px 391.485px;" class="animable"><path d="M308.5,412.8c-1.08-19.23-1.82-32.55-1.82-32.55-68.74,16.65-105.94-10.08-105.94-10.08s-3,18.93-6.82,42.63Z"
style="fill: rgb(255, 255, 255); transform-origin: 251.21px 391.485px;" id="elphxyk7y52h" class="animable"></path><path d="M308.5,413.3H193.92a.49.49,0,0,1-.38-.18.51.51,0,0,1-.11-.4c3.81-23.7,6.82-42.63,6.82-42.63a.51.51,0,0,1,.3-.38.49.49,0,0,1,.48.05c.38.27,37.83,26.4,105.53,10a.49.49,0,0,1,.41.08.48.48,0,0,1,.2.38s.74,13.31,1.83,32.54a.51.51,0,0,1-.5.53Zm-114-1H308c-.93-16.58-1.61-28.66-1.76-31.42-62.89,15-99.78-6.46-105.1-9.86C200.52,374.75,197.83,391.66,194.51,412.3Z"
style="fill: rgb(38, 50, 56); transform-origin: 251.212px 391.485px;" id="elv6ws62mxxi" class="animable"></path></g></g><path d="M111.61,224.71c-.1,3.11-2.11,5.91-2.18,9-.06,2.9,1.58,5.54,2.14,8.38.6,3.06,0,6.2.39,9.27a22.17,22.17,0,0,0,1.61,5.75,8.75,8.75,0,0,0,2.76,3.67c2,1.48,4.85,1.42,7.26,1.42a11.07,11.07,0,0,0,7-2,15.5,15.5,0,0,0,3.64-4.92,10.69,10.69,0,0,0,1.71-4.81,7.48,7.48,0,0,0-.6-2.83c-2-4.91-7-8.41-7.84-13.63-.36-2.23.1-4.5.3-6.74s.07-4.69-1.29-6.5c-2.25-3-7.52-3.63-11-3.62C110.43,217.16,111.74,221,111.61,224.71Z"
style="fill: rgb(255, 255, 255); transform-origin: 122.684px 239.684px;" id="elqg3kocwasof" class="animable"></path><path d="M123,262.71c-2.34,0-4.93-.07-6.91-1.51a9.24,9.24,0,0,1-2.93-3.88,22.48,22.48,0,0,1-1.64-5.88,27.41,27.41,0,0,1-.14-4.33,23.69,23.69,0,0,0-.25-4.91,22.66,22.66,0,0,0-1-3.2,11.4,11.4,0,0,1-.06-10,14.24,14.24,0,0,0,1.06-4.31h0c0-.68,0-1.37,0-2-.07-1.92-.14-3.73,1-4.89a4.59,4.59,0,0,1,3.44-1.13c3.22,0,8.91.47,11.42,3.82,1.22,1.62,1.66,3.79,1.39,6.84-.05.56-.11,1.12-.18,1.67a18,18,0,0,0-.13,4.95c.46,2.85,2.22,5.16,4.09,7.6a25.87,25.87,0,0,1,3.72,5.92,8,8,0,0,1,.64,3,11.11,11.11,0,0,1-1.77,5,16.4,16.4,0,0,1-3.77,5.08,11.44,11.44,0,0,1-7.31,2.12H123Zm-10.84-38a15.4,15.4,0,0,1-1.11,4.61,10.4,10.4,0,0,0,.06,9.32,23.54,23.54,0,0,1,1,3.35,23.85,23.85,0,0,1,.27,5.12,26.55,26.55,0,0,0,.13,4.18,21.6,21.6,0,0,0,1.57,5.61,8.17,8.17,0,0,0,2.6,3.47c1.82,1.32,4.4,1.32,6.67,1.32h.29a10.45,10.45,0,0,0,6.69-1.91,15.25,15.25,0,0,0,3.51-4.78,10.3,10.3,0,0,0,1.65-4.57,7,7,0,0,0-.57-2.64,24.48,24.48,0,0,0-3.59-5.68c-1.85-2.44-3.77-5-4.28-8a19,19,0,0,1,.13-5.22c.06-.55.13-1.1.17-1.65.25-2.79-.13-4.74-1.19-6.15-2.33-3.11-8.21-3.39-10.62-3.42a3.71,3.71,0,0,0-2.72.82c-.81.85-.76,2.38-.69,4.16,0,.69.06,1.4,0,2.11Z"
style="fill: rgb(38, 50, 56); transform-origin: 122.732px 239.681px;" id="ely8uj047ui7" class="animable"></path><path d="M118.1,261.65l3.34,26.51s18.88-4.64,24.45-12.09l-10.71-23.56"
style="fill: rgb(255, 255, 255); transform-origin: 131.995px 270.335px;" id="el2y5c8bwri2e" class="animable"></path><path d="M121.44,288.66a.52.52,0,0,1-.29-.09.5.5,0,0,1-.21-.35l-3.34-26.51a.5.5,0,0,1,.43-.56.49.49,0,0,1,.56.44l3.27,25.94c3.09-.82,18.38-5.14,23.45-11.53l-10.59-23.28a.52.52,0,0,1,.25-.67.5.5,0,0,1,.66.25l10.72,23.56a.52.52,0,0,1-.06.51c-5.61,7.5-24,12.08-24.74,12.27Z"
style="fill: rgb(38, 50, 56); transform-origin: 131.993px 270.333px;" id="el0bizmnd21d7" class="animable"></path><path d="M152.53,277.9l6,13.4L220.27,255,232,239.35,240.08,259l-16.5,38.64a311,311,0,0,1-40.51,24,213.73,213.73,0,0,1-20.65,9.18c-6.73,2.52-13.74,4.24-20.68,6.09a2.1,2.1,0,0,1-1.46,0c-.31-.16-.44-.54-.72-.77-5.08-4.09-17.82-40.55-19.13-52.16Z"
style="fill: rgb(38, 50, 56); transform-origin: 180.255px 288.195px;" id="el8rcre7ekpua" class="animable"></path><path d="M318.61,224.83l28.56,40.61a31.58,31.58,0,0,1,1.63,33.76c-11,19.4-28.59,49-37.76,57.56,0,0,4.28,8.76,3.63,12s-6.69,3.9-6.69,3.9l9.29,15.94S266.88,433.15,196,375.28l14.75-51.7L202.94,318l31.33-104.65,32.2-3.46,9.79,25.92,10.57-12.5,10.61,12.5Z"
style="fill: rgb(38, 50, 56); transform-origin: 274.459px 307.432px;" id="elhctfywvvd6a" class="animable"></path><path d="M316.47,285.19a.49.49,0,0,1-.47-.34.51.51,0,0,1,.31-.64c8.68-3,16.75-4.31,27.85-4.67a.52.52,0,0,1,.52.49.5.5,0,0,1-.49.51c-11,.36-19,1.7-27.56,4.62A.41.41,0,0,1,316.47,285.19Z"
style="fill: rgb(255, 255, 255); transform-origin: 330.327px 282.365px;" id="elcknv3blw765" class="animable"></path><path d="M290.2,381.68a.51.51,0,0,1-.4-.81,47.93,47.93,0,0,1,19.23-15,.5.5,0,0,1,.39.92,46.81,46.81,0,0,0-18.82,14.65A.51.51,0,0,1,290.2,381.68Z"
style="fill: rgb(255, 255, 255); transform-origin: 299.705px 373.759px;" id="el75iru86g85b" class="animable"></path><path d="M153.65,312a.71.71,0,0,1-.2,0,.52.52,0,0,1-.27-.66,65.45,65.45,0,0,0,4.66-17.86.5.5,0,0,1,1,.11,67,67,0,0,1-4.73,18.14A.51.51,0,0,1,153.65,312Z"
style="fill: rgb(255, 255, 255); transform-origin: 155.994px 302.52px;" id="elobbvnexe6i" class="animable"></path><path d="M164.65,306.22h-.06a.5.5,0,0,1-.44-.55A17.42,17.42,0,0,0,161.23,294a.51.51,0,0,1,.12-.7.5.5,0,0,1,.7.13,18.46,18.46,0,0,1,3.1,12.39A.51.51,0,0,1,164.65,306.22Z"
style="fill: rgb(255, 255, 255); transform-origin: 163.199px 299.715px;" id="elzeuiq3gq9v" class="animable"></path><path d="M223.14,280.54a.51.51,0,0,1-.17,0,.49.49,0,0,1-.3-.63c1.32-3.79,10-24.74,13.15-32.23a.5.5,0,1,1,.92.39c-3.11,7.28-11.84,28.48-13.13,32.17A.5.5,0,0,1,223.14,280.54Z"
style="fill: rgb(255, 255, 255); transform-origin: 229.714px 263.952px;" id="elgwy3ynh7quo" class="animable"></path><path d="M286.83,336.17a.5.5,0,0,1-.49-.37L283,323.3l-52.39-31a.5.5,0,0,1,.51-.86l52.56,31.09a.5.5,0,0,1,.23.3l2.42,9V321.14a.52.52,0,0,1,.11-.32l22.39-27.61a.51.51,0,0,1,.71-.07.49.49,0,0,1,.07.7l-22.28,27.47v14.36a.51.51,0,0,1-.44.5Z"
style="fill: rgb(255, 255, 255); transform-origin: 270.049px 313.772px;" id="elltp4p2l29jn" class="animable"></path><path d="M289.29,370.67a.54.54,0,0,1-.25-.06l-60.76-34a.5.5,0,0,1-.2-.68.51.51,0,0,1,.69-.19l60.49,33.81,14.31-10.18a.51.51,0,0,1,.7.11.5.5,0,0,1-.12.7l-14.57,10.37A.52.52,0,0,1,289.29,370.67Z"
style="fill: rgb(255, 255, 255); transform-origin: 266.191px 353.173px;" id="el5ib8fjk72l3" class="animable"></path><ellipse cx="178.24" cy="218.42" rx="56.86" ry="57.94"
style="fill: rgb(38, 50, 56); transform-origin: 178.24px 218.42px;" id="elv7pftw845rd" class="animable"></ellipse><circle cx="182.04" cy="214.8" r="54.87"
style="fill: rgb(227, 146, 146); transform-origin: 182.04px 214.8px;" id="ellkh39txw2e9" class="animable"></circle><path d="M182,270.17A55.37,55.37,0,1,1,237.4,214.8,55.43,55.43,0,0,1,182,270.17Zm0-109.73A54.37,54.37,0,1,0,236.4,214.8,54.43,54.43,0,0,0,182,160.44Z"
style="fill: rgb(38, 50, 56); transform-origin: 182.03px 214.8px;" id="elnn28emmtsod" class="animable"></path><polygon points="160.33 193.78 177.37 241.8 211.76 203.71 160.33 193.78"
style="fill: rgb(255, 255, 255); transform-origin: 186.045px 217.79px;" id="elq7s0nmdrxm" class="animable"></polygon><path d="M226.85,201.68a.51.51,0,0,1-.49-.39,43.25,43.25,0,0,0-23.47-29.13.5.5,0,0,1,.43-.91,44.29,44.29,0,0,1,24,29.81.5.5,0,0,1-.37.6Z"
style="fill: rgb(255, 255, 255); transform-origin: 214.967px 186.441px;" id="ele4dr0b5fvqj" class="animable"></path><path d="M198.25,170.22a.45.45,0,0,1-.16,0,43.3,43.3,0,0,0-9.83-2.11.5.5,0,1,1,.1-1,44,44,0,0,1,10,2.16.5.5,0,0,1-.16,1Z"
style="fill: rgb(255, 255, 255); transform-origin: 193.295px 168.692px;" id="eli9088mlau7" class="animable"></path><path d="M222.21,288.67s-10.55,21.91-18.49,24.3L191.84,297.8l-10-12.78-2.55-3.15v0l-.06-.1-10.69-19.29-1.2-2.17-.36-1.58-.85-3.72-.06-.27.15-.45,2-5.57,3.57-4.1,0-.15,1.29-6.58.14-.71,3.4-2.1s0,0,0-.05c-.24-.64-2.75-7.79-.79-11.95a4.66,4.66,0,0,1,2-2.2,5.46,5.46,0,0,1,1.25-.52,2,2,0,0,1,.32-.46c.31-.35.85-.62,1.48.27h0a5.63,5.63,0,0,1,.68,1.43c.11.27.22.59.33.94,0,.16.09.32.14.49s.11.36.17.55c.12.4.25.8.38,1.2,1.84,5.72,5.28,15.44,5.54,16.14a0,0,0,0,0,0,0l8.31,2.74,1,.32.49,1.34,2.9,7.94.19.52.09.64,1.84,15.22.07.07L218.7,285.2Z"
style="fill: rgb(255, 255, 255); transform-origin: 194.14px 266.27px;" id="elcyqdzcjtjs9" class="animable"></path><path d="M223.14,289.89a.49.49,0,0,1-.34-.14c-14.06-13.14-20.19-19.69-20.25-19.75a.51.51,0,0,1-.13-.29l-.94-9.63a55.7,55.7,0,0,0-2.16-10.4,11.88,11.88,0,0,0-3-5.41c-1.63-1.53-3.69-1.91-5.88-2.32-.81-.15-1.65-.3-2.47-.52a.49.49,0,0,1-.35-.61.5.5,0,0,1,.61-.36c.78.21,1.6.36,2.4.51,2.23.41,4.53.84,6.38,2.58a12.87,12.87,0,0,1,3.25,5.84A57,57,0,0,1,202.47,260l.93,9.46c.9.95,7.1,7.44,20.08,19.58a.5.5,0,0,1-.34.87Z"
style="fill: rgb(38, 50, 56); transform-origin: 205.622px 265.177px;" id="elrjnzyy58n9" class="animable"></path><path d="M191.4,249.81a.51.51,0,0,1-.46-.31c-1.06-2.59-2.14-5.33-3.29-8.38h0v0c-.41-1.05-.81-2.12-1.2-3.18-1.63-4.39-3-8.82-4.35-13-.13-.39-.26-.8-.38-1.2l0-.14a4.14,4.14,0,0,1-.13-.43l-.52-1.6c0-.08-.05-.15-.07-.23a2.32,2.32,0,0,0-.43-.8l-.05-.06a.81.81,0,0,0-.92-.11,2.52,2.52,0,0,0-.58.28,5.32,5.32,0,0,0-.81.6,6.18,6.18,0,0,0-1.53,2.11l-.1.22c-1.09,2.66-.49,5.9,0,8.27.19,1,.39,2,.61,3.08h0a.09.09,0,0,0,0,.05c1,4.63,2.27,9.11,3.39,12.88a.5.5,0,0,1-.95.29c-1.13-3.79-2.42-8.29-3.42-13a.09.09,0,0,1,0,0h0c-.23-1-.44-2.07-.63-3.11-.46-2.49-1.1-5.9.1-8.83l.11-.25a7.48,7.48,0,0,1,1.78-2.45,6.29,6.29,0,0,1,1-.71,3.79,3.79,0,0,1,.8-.37,2.25,2.25,0,0,1,.71-.13,1.75,1.75,0,0,1,1.27.53l.06.08a3.51,3.51,0,0,1,.62,1.15l.59,1.84c0,.15.08.28.12.41l0,.14c.12.4.25.8.38,1.19,1.31,4.16,2.71,8.58,4.34,12.94.39,1.07.79,2.13,1.19,3.18a0,0,0,0,1,0,0h0c1.16,3,2.23,5.77,3.29,8.35a.5.5,0,0,1-.28.65A.41.41,0,0,1,191.4,249.81Z"
style="fill: rgb(38, 50, 56); transform-origin: 183.484px 234.557px;" id="elh0hb276zxxq" class="animable"></path><path d="M173,251.63a.51.51,0,0,1-.48-.36,30.16,30.16,0,0,1-1.22-6.8,12.63,12.63,0,0,1,1.29-6.73l.08-.15a5.71,5.71,0,0,1,3.44-2.34.5.5,0,1,1,.27,1,4.7,4.7,0,0,0-2.86,1.92l-.07.12a11.58,11.58,0,0,0-1.15,6.17,28.85,28.85,0,0,0,1.19,6.57.51.51,0,0,1-.35.62Z"
style="fill: rgb(38, 50, 56); transform-origin: 174.009px 243.441px;" id="elfbv61micoio" class="animable"></path><path d="M202.94,311.68a.51.51,0,0,1-.39-.18L178.9,282.18l-.06-.09a191.35,191.35,0,0,1-10.76-19.42c-.66-1.43-1.18-2.67-1.58-3.79a24.73,24.73,0,0,1-.93-3,.66.66,0,0,1,0-.14c0-.27,0-.54.06-.82s0-.49.1-.74a16.83,16.83,0,0,1,2-5.73,12.49,12.49,0,0,1,3.74-4.27.5.5,0,0,1,.69.15.49.49,0,0,1-.15.69,11.76,11.76,0,0,0-3.41,3.93,15.68,15.68,0,0,0-1.91,5.4,5.94,5.94,0,0,0-.09.69,5.52,5.52,0,0,0-.06.68,23.67,23.67,0,0,0,.88,2.82c.39,1.09.9,2.31,1.55,3.71a193,193,0,0,0,10.65,19.23.58.58,0,0,1,.07.11l23.62,29.28a.5.5,0,0,1-.07.7A.5.5,0,0,1,202.94,311.68Z"
style="fill: rgb(38, 50, 56); transform-origin: 184.494px 277.89px;" id="elphc40sy45rg" class="animable"></path><path d="M227,286.49a48.55,48.55,0,0,1-5.1,10.26c-7.56,11.93-18.77,24.6-18.77,24.6-7.15-6.53-17.06-21.66-17.06-21.66,4.91-3,12.77-11.73,17.19-17.32,4.24-5.37,7.31-9.94,7.31-9.94Z"
style="fill: rgb(255, 255, 255); transform-origin: 206.535px 296.89px;" id="els4xo8yqeoa" class="animable"></path><path d="M203.11,321.85a.51.51,0,0,1-.34-.13c-7.11-6.5-17-21.6-17.14-21.76a.47.47,0,0,1-.07-.38.52.52,0,0,1,.22-.32c5-3.06,12.93-12,17.07-17.2s7.25-9.86,7.28-9.9a.51.51,0,0,1,.35-.22.5.5,0,0,1,.39.11l16.44,14.06a.52.52,0,0,1,.15.55A49.62,49.62,0,0,1,222.3,297c-7.49,11.83-18.71,24.54-18.82,24.66a.51.51,0,0,1-.35.17Zm-16.37-22c1.63,2.44,10,14.84,16.33,20.79,1.93-2.21,11.67-13.54,18.39-24.15a48.61,48.61,0,0,0,4.94-9.83l-15.75-13.47c-.9,1.3-3.58,5.16-7,9.5C199.57,287.82,191.84,296.48,186.74,299.84Z"
style="fill: rgb(38, 50, 56); transform-origin: 206.516px 296.892px;" id="eleakbkm685vd" class="animable"></path><g id="el9nu8tvil7tf"><path d="M227,286.49c-1.18,3.46-5.32,9.38-7.44,12.71L202.08,284c4.25-5.37,8.47-11.52,8.47-11.52Z"
style="opacity: 0.1; transform-origin: 214.54px 285.84px;" class="animable"></path></g><path d="M111.55,221.46c-.11-1.5-.14-3.28.36-3.89a5.83,5.83,0,0,1,1.91-1.36c6.85-3.57,13.71-7.14,20.79-10.26.62-.27,1.4-.53,1.95-.12a1.7,1.7,0,0,1,.44,1.61,9.32,9.32,0,0,1-2.51,4.8,22.7,22.7,0,0,1-6.16,4.91,20.13,20.13,0,0,0-2.3,1.32,21,21,0,0,0-2.6,2.48,22.49,22.49,0,0,1-2,1.79"
style="fill: rgb(255, 255, 255); transform-origin: 124.267px 214.178px;" id="el0c4hy8mltrme" class="animable"></path><path d="M121.44,223.24a.47.47,0,0,1-.39-.19.49.49,0,0,1,.08-.7,21.43,21.43,0,0,0,1.94-1.75q.32-.32.6-.63a15.73,15.73,0,0,1,2.07-1.91,14.17,14.17,0,0,1,1.79-1.05l.56-.3a21.91,21.91,0,0,0,6-4.81,8.65,8.65,0,0,0,2.4-4.52c0-.26.07-.9-.26-1.15s-1,0-1.44.18c-7.06,3.11-14,6.74-20.76,10.25a5.14,5.14,0,0,0-1.75,1.22c-.17.21-.43,1-.25,3.55a.5.5,0,0,1-1,.07c-.16-2.33,0-3.64.47-4.25a6.33,6.33,0,0,1,2.07-1.48c6.75-3.52,13.72-7.15,20.81-10.27a2.41,2.41,0,0,1,2.46-.07,2.15,2.15,0,0,1,.64,2.07,9.59,9.59,0,0,1-2.64,5.07,23.18,23.18,0,0,1-6.29,5l-.57.3a10.64,10.64,0,0,0-3.6,2.77c-.2.22-.41.43-.61.64a23.57,23.57,0,0,1-2,1.83A.51.51,0,0,1,121.44,223.24Z"
style="fill: rgb(38, 50, 56); transform-origin: 124.253px 214.183px;" id="elimqu771d2z" class="animable"></path><path d="M117.5,221.53a10,10,0,0,1,1.52-.61c5-1.51,10.45-1.15,15.5-2.57,2.55-.72,5-1.9,7.7-1.88.8,0,1.89.33,1.8,1.33s-.78,1.4-1.31,2a21.22,21.22,0,0,1-1.88,2,16.45,16.45,0,0,1-4.71,2.9,40.24,40.24,0,0,1-7.8,1.85c-2.56.49-5,1.28-7.51,2-.62.18-1.25.34-1.89.47"
style="fill: rgb(255, 255, 255); transform-origin: 130.763px 222.745px;" id="el61pwdqyh2yp" class="animable"></path><path d="M118.92,229.54a.51.51,0,0,1-.49-.4.5.5,0,0,1,.39-.59c.56-.11,1.15-.26,1.85-.46l1.52-.44a61.2,61.2,0,0,1,6-1.57l1.71-.31a28.36,28.36,0,0,0,6-1.51,16.1,16.1,0,0,0,4.57-2.81,13.94,13.94,0,0,0,1.34-1.42l.5-.57c.1-.12.22-.24.33-.35a2.45,2.45,0,0,0,.85-1.36.56.56,0,0,0-.12-.41,1.74,1.74,0,0,0-1.19-.37,16.88,16.88,0,0,0-5.41,1.18c-.71.24-1.43.48-2.15.68a45.58,45.58,0,0,1-7.64,1.25,42.39,42.39,0,0,0-7.84,1.32,8.77,8.77,0,0,0-1.45.58.5.5,0,0,1-.67-.22.52.52,0,0,1,.22-.68,11.23,11.23,0,0,1,1.61-.64,43.53,43.53,0,0,1,8-1.36,45,45,0,0,0,7.48-1.21c.71-.2,1.4-.43,2.1-.67a18,18,0,0,1,5.74-1.23,2.68,2.68,0,0,1,1.92.7,1.48,1.48,0,0,1,.37,1.17,3.24,3.24,0,0,1-1.12,2l-.31.32-.48.56a16.87,16.87,0,0,1-1.43,1.52,17.18,17.18,0,0,1-4.86,3,30.06,30.06,0,0,1-6.21,1.56l-1.69.31c-2,.39-4,1-5.94,1.55l-1.52.44c-.72.21-1.35.36-1.93.48Z"
style="fill: rgb(38, 50, 56); transform-origin: 130.72px 222.774px;" id="elvzl6629c6yc" class="animable"></path><path d="M116.54,228.56a8.79,8.79,0,0,1,3.48-1.71,26,26,0,0,1,7.27,0,72.06,72.06,0,0,0,12.72-.11,5.48,5.48,0,0,1,2.06,0c3.93,1.11-1.37,4.93-2.85,5.57-5.83,2.53-12.73,2.65-19,2.5"
style="fill: rgb(255, 255, 255); transform-origin: 130.016px 230.724px;" id="elktqo6tv89a" class="animable"></path><path d="M123.35,235.43c-1,0-2.09,0-3.12,0a.5.5,0,0,1-.49-.51.51.51,0,0,1,.51-.49c6.43.16,13.16,0,18.77-2.46,1.19-.51,4-2.67,4-3.83,0-.34-.37-.61-1.05-.8a5.12,5.12,0,0,0-1.84,0,72.61,72.61,0,0,1-12.85.12l-1-.08a22,22,0,0,0-6.18,0,8.44,8.44,0,0,0-3.27,1.61.5.5,0,0,1-.7-.09.5.5,0,0,1,.08-.7,9.29,9.29,0,0,1,3.69-1.8,23,23,0,0,1,6.47-.06l.94.08a72,72,0,0,0,12.63-.11,5.87,5.87,0,0,1,2.25.07c1.41.4,1.75,1.14,1.78,1.69.14,2-3.74,4.46-4.58,4.82C134.54,235,128.92,235.43,123.35,235.43Z"
style="fill: rgb(38, 50, 56); transform-origin: 129.998px 230.785px;" id="eldrdx4zjkk35" class="animable"></path><path d="M123.85,235.21s8.32,11,2.26,21.49"
style="fill: rgb(255, 255, 255); transform-origin: 126.085px 245.955px;" id="eljgbgz41mf2a" class="animable"></path><path d="M126.11,257.2a.54.54,0,0,1-.25-.06.52.52,0,0,1-.19-.69c5.83-10.11-2.14-20.83-2.22-20.93a.5.5,0,0,1,.8-.61c.08.11,8.45,11.34,2.29,22A.5.5,0,0,1,126.11,257.2Z"
style="fill: rgb(38, 50, 56); transform-origin: 126.074px 245.956px;" id="elbu8fdz7o5d4" class="animable"></path><path d="M155.22,281.85s-3.29,5.35-13.72,9.51a70.05,70.05,0,0,1-20.06,4.4l-3.06-21.5s6.7,1.21,16.45-2.93a51.72,51.72,0,0,0,10.32-5.93Z"
style="fill: rgb(255, 255, 255); transform-origin: 136.8px 280.58px;" id="el8iiv8czvy2b" class="animable"></path><path d="M121.44,296.26a.49.49,0,0,1-.49-.43l-3.06-21.5a.49.49,0,0,1,.14-.43.54.54,0,0,1,.44-.13c.06,0,6.68,1.12,16.17-2.9A51.26,51.26,0,0,0,144.85,265a.52.52,0,0,1,.4-.09.55.55,0,0,1,.33.23l10.06,16.45a.51.51,0,0,1,0,.53c-.14.22-3.49,5.53-13.95,9.7a71.14,71.14,0,0,1-20.21,4.44ZM119,274.84l2.9,20.38a69.82,69.82,0,0,0,19.45-4.32c8.86-3.54,12.51-8,13.3-9.06L145,266.13a51.94,51.94,0,0,1-10,5.66C126.9,275.24,120.92,275,119,274.84Z"
style="fill: rgb(38, 50, 56); transform-origin: 136.799px 280.581px;" id="elmc6hoonlng" class="animable"></path><g id="elts0qw3wp73q"><path d="M155.22,281.85s-3.29,5.35-13.72,9.51l-6.67-20a51.72,51.72,0,0,0,10.32-5.93Z"
style="opacity: 0.1; transform-origin: 145.025px 278.395px;" class="animable"></path></g><path d="M308.58,169.84s2.44,1.22,4.87-2.92,4.55-15.48-.8-16.45c-7.23-3.52-9.67,1.59-9.67,1.59l-4.39-15.83s-1.21-14.62-10.47-13.4-14.62,11.45-14.62,11.45l-25.09,1-.73,14.13s-4.14-2.92-5.36.73-1.22,15.84,9,19.73c0,0,4,18.15,16.26,28.08l6,.35-1.33,14.21a24,24,0,0,0,14.13,12.42c10.24,3.17,19.7-16.76,19.7-16.76S306.32,183.47,308.58,169.84Z"
style="fill: rgb(255, 255, 255); transform-origin: 278.881px 174.015px;" id="elht0fmj0f6an" class="animable"></path><path d="M255.81,137.5c4.51,2.69,9.33,5,14.52,5.88s10.8,0,15-3.11a38.91,38.91,0,0,0,1,10.34c.92,3.25,2.89,6.35,5.9,7.87.32,4,.7,8,1,12.08.27,3.39.42,7.78-.92,10.91a21.77,21.77,0,0,1-7.15,9.38c-3.3,2.45-6,3.35-10.84,6.53-.35.23-.18,1.06.11.76a24,24,0,0,0,12.74-5.06s8.27,16.09,8.49,28.38l10.45-13.32s-.62-28.41,2.95-38.48c0,0,5.14.19,6.48-9.41s-4.47-10.5-4.47-10.5-19.88-29.27-24.79-26.59S255.81,137.5,255.81,137.5Z"
style="fill: rgb(227, 146, 146); transform-origin: 285.775px 172.223px;" id="el3hy48rdnhsu" class="animable"></path><g id="el0fl6gqssa6l"><path d="M255.81,137.5c4.51,2.69,9.33,5,14.52,5.88s10.8,0,15-3.11a38.91,38.91,0,0,0,1,10.34c.92,3.25,2.89,6.35,5.9,7.87.32,4,.7,8,1,12.08.27,3.39.42,7.78-.92,10.91a21.77,21.77,0,0,1-7.15,9.38c-3.3,2.45-6,3.35-10.84,6.53-.35.23-.18,1.06.11.76a24,24,0,0,0,12.74-5.06s8.27,16.09,8.49,28.38l10.45-13.32s-.62-28.41,2.95-38.48c0,0,5.14.19,6.48-9.41s-4.47-10.5-4.47-10.5-19.88-29.27-24.79-26.59S255.81,137.5,255.81,137.5Z"
style="fill: rgb(255, 255, 255); opacity: 0.5; transform-origin: 285.775px 172.223px;" class="animable"></path></g><path d="M250.54,169.29h-.12c-4.67-1.09-7.89-4.61-9-9.93-1.06-4.87-.11-10.18,1.37-11.33a2.21,2.21,0,0,1,1.81-.44c2.23.38,4.42,3.73,4.51,3.87a.5.5,0,0,1-.84.55c-.56-.87-2.35-3.18-3.84-3.44a1.27,1.27,0,0,0-1,.25c-1,.76-2.06,5.53-1,10.33.76,3.48,2.81,7.89,8.3,9.17a.5.5,0,0,1,.37.6A.49.49,0,0,1,250.54,169.29Z"
style="fill: rgb(38, 50, 56); transform-origin: 246.041px 158.422px;" id="elsw9djnfxlhm" class="animable"></path><path d="M318.22,227.59a.66.66,0,0,1,0,.09,66.74,66.74,0,0,1-7.25,8.13v0c-.4.39-.82.77-1.23,1.14a85.93,85.93,0,0,1-18.31,12.9l-.15.09-4.43-26.64,0,0a61.8,61.8,0,0,0,16.67-14.06l.32-.34c.69-.76,1.87-1.84,2.23-2.4,1.22-1.89,1-1.08,1-1.08l-.8-3.79-.42-1.93.45.21C318.25,208.58,318.49,226.05,318.22,227.59Z"
style="fill: rgb(255, 255, 255); transform-origin: 302.563px 224.82px;" id="eljyal04iqole" class="animable"></path><path d="M291.26,250.46a.46.46,0,0,1-.22-.06.48.48,0,0,1-.27-.36l-4.44-26.64a.54.54,0,0,1,.14-.43.87.87,0,0,1,.25-.15,102.29,102.29,0,0,0,15.84-13.26l1.12-1.2a51.37,51.37,0,0,0,4.16-4.78.5.5,0,0,1,.82.58,52.9,52.9,0,0,1-4.25,4.88l-1.12,1.2a104,104,0,0,1-15.91,13.34l4.25,25.57a159.57,159.57,0,0,0,17.77-12.56c.42-.34.83-.7,1.23-1.05,3.59-3.14,6-5.85,7.12-8.08.41-1.64-1.18-18.27-11.34-27a.5.5,0,1,1,.65-.76c10.68,9.14,12.1,26.19,11.65,28,0,0,0,.12,0,.13-1.23,2.41-3.7,5.23-7.38,8.45-.4.36-.83.72-1.25,1.07a162.24,162.24,0,0,1-18.37,12.94.88.88,0,0,1-.18.1A.46.46,0,0,1,291.26,250.46Z"
style="fill: rgb(38, 50, 56); transform-origin: 302.554px 225.02px;" id="el8eifvxpigqq" class="animable"></path><path d="M286.83,223.32,275.06,250s-12.4-16.6-12.76-30.41c1.8-7.45,3.74-11.6,11.43-16.11l-.87,5.37S274.28,221.54,286.83,223.32Z"
style="fill: rgb(255, 255, 255); transform-origin: 274.565px 226.74px;" id="elybk63sd92e7" class="animable"></path><path d="M275.06,250.46a.49.49,0,0,1-.41-.22c-.11-.16-11.47-16.89-12.84-30.64a.33.33,0,0,1,0-.14c2.54-13.72,11.07-16,11.43-16.08a.51.51,0,0,1,.61.37.49.49,0,0,1-.36.6c-.09,0-8.25,2.24-10.68,15.22,1.21,11.84,10,26,12.17,29.35l11.23-25.42a59.65,59.65,0,0,1-14.62-16.07.5.5,0,0,1,.16-.69.51.51,0,0,1,.69.16,57.61,57.61,0,0,0,14.67,16,.51.51,0,0,1,.18.62l-11.77,26.63a.5.5,0,0,1-.41.3Z"
style="fill: rgb(38, 50, 56); transform-origin: 274.567px 226.913px;" id="el5u8gaaqzosd" class="animable"></path><g id="el4hr2zmvtkya"><path d="M318.22,227.47l0,.1c-1.29,2.52-4,5.4-7.23,8.22,0,0,0-.06,0-.08h0c-1.52-13.17-8-25.8-8-25.8l3.88-4.5-.51-5.61C316.78,209.42,318.49,225.93,318.22,227.47Z"
style="opacity: 0.12; transform-origin: 310.618px 217.795px;" class="animable"></path></g><path d="M273,209.08l-.14,0a.5.5,0,0,1-.35-.61,28.69,28.69,0,0,0,.61-10.12.5.5,0,0,1,.43-.56.5.5,0,0,1,.56.43,29.27,29.27,0,0,1-.63,10.51A.5.5,0,0,1,273,209.08Z"
style="fill: rgb(38, 50, 56); transform-origin: 273.409px 203.433px;" id="elkd3u4sbx41a" class="animable"></path><path d="M306.54,206.49a.5.5,0,0,1-.5-.47c-1.11-23.07,2.5-36.37,2.54-36.5a.52.52,0,0,1,.62-.35.5.5,0,0,1,.34.62c0,.13-3.6,13.29-2.51,36.18a.49.49,0,0,1-.47.52Z"
style="fill: rgb(38, 50, 56); transform-origin: 307.694px 187.823px;" id="elr28546ce1fq" class="animable"></path><path d="M268.83,172.62a7.88,7.88,0,0,1-1.79-.25c-1.65-.39-3.63-1.06-4.31-2.84a4.56,4.56,0,0,1,.89-4.19c.35-.49.74-.94,1.12-1.38a12.17,12.17,0,0,0,1.33-1.69c1.59-2.58,1.14-5.86.49-9.27a.51.51,0,0,1,.4-.59.5.5,0,0,1,.58.4c.66,3.47,1.16,7.1-.62,10a13.71,13.71,0,0,1-1.42,1.83,15.65,15.65,0,0,0-1.07,1.3,3.78,3.78,0,0,0-.76,3.25c.51,1.34,2.19,1.89,3.6,2.22a3.74,3.74,0,0,0,2.74-.06.5.5,0,1,1,.53.85A3.24,3.24,0,0,1,268.83,172.62Z"
style="fill: rgb(38, 50, 56); transform-origin: 266.664px 162.512px;" id="elrf122zru8ko" class="animable"></path><path d="M262.35,154.75c.09,1.87-.66,3.42-1.67,3.46s-1.89-1.43-2-3.3.66-3.42,1.67-3.47S262.27,152.88,262.35,154.75Z"
style="fill: rgb(38, 50, 56); transform-origin: 260.513px 154.825px;" id="el2fpufo5bps2" class="animable"></path><path d="M282.91,154.26c.09,1.87-.66,3.42-1.67,3.46s-1.89-1.43-2-3.3.66-3.42,1.67-3.47S282.83,152.39,282.91,154.26Z"
style="fill: rgb(38, 50, 56); transform-origin: 281.073px 154.335px;" id="elos82v8efof" class="animable"></path><path d="M273.53,180a12.3,12.3,0,0,1-5.36-1.1.5.5,0,0,1-.13-.7.49.49,0,0,1,.69-.13,12.73,12.73,0,0,0,7.25.69,10.47,10.47,0,0,0,6.81-4.8.52.52,0,0,1,.7-.15.51.51,0,0,1,.14.7,11.39,11.39,0,0,1-7.48,5.23A12.71,12.71,0,0,1,273.53,180Z"
style="fill: rgb(38, 50, 56); transform-origin: 275.832px 176.868px;" id="el8jnyg2pdw" class="animable"></path><path d="M273.89,144.26a.51.51,0,0,1-.4-.2.5.5,0,0,1,.1-.7c4.37-3.21,7.36-3.33,11.86-.49a.51.51,0,0,1,.16.69.5.5,0,0,1-.69.15c-4.17-2.63-6.68-2.52-10.73.45A.49.49,0,0,1,273.89,144.26Z"
style="fill: rgb(38, 50, 56); transform-origin: 279.536px 142.55px;" id="elzqtio5m3w78" class="animable"></path><path d="M255.49,146.05a.52.52,0,0,1-.33-.12.49.49,0,0,1-.06-.7,7.13,7.13,0,0,1,9.22-1.22.51.51,0,0,1,.12.7.5.5,0,0,1-.69.12,6.1,6.1,0,0,0-7.88,1A.49.49,0,0,1,255.49,146.05Z"
style="fill: rgb(38, 50, 56); transform-origin: 259.755px 144.449px;" id="ellggpeipv1x" class="animable"></path><path d="M270.58,198.59a21.2,21.2,0,0,1-3.06-.18.5.5,0,0,1-.23-.09c-.27-.22-6.75-5.28-11.93-15.79-8.82-17.87-7.13-43.7-7.11-44a.5.5,0,0,1,1,.07c0,.26-1.69,25.82,7,43.45a47.89,47.89,0,0,0,11.55,15.35,30.9,30.9,0,0,0,10.43-.78c6.47-1.57,12.6-6.64,16.62-10.61a.49.49,0,0,1,.7,0,.48.48,0,0,1,0,.7c-4.1,4.06-10.39,9.24-17.08,10.88A33.89,33.89,0,0,1,270.58,198.59Z"
style="fill: rgb(38, 50, 56); transform-origin: 271.905px 168.328px;" id="el754ubrij3tb" class="animable"></path><path d="M295.2,186.91a.54.54,0,0,1-.25-.06.51.51,0,0,1-.19-.68c4.95-8.87,6-14.54,6.05-14.59a.5.5,0,0,1,1,.18c-.05.23-1.13,5.87-6.17,14.89A.49.49,0,0,1,295.2,186.91Z"
style="fill: rgb(38, 50, 56); transform-origin: 298.259px 179.036px;" id="el1sgir2cgpzu" class="animable"></path><path d="M308.5,170.18c-2.84,0-3.81-1.71-3.86-1.8a.5.5,0,0,1,.88-.48c0,.08,1.17,1.95,4.91,1,2.32-.58,5.05-6.93,5.13-11.93,0-1.92-.33-5.27-3-6-6.2-1.75-9.46,7.37-9.49,7.46a.51.51,0,0,1-.64.31.5.5,0,0,1-.3-.64c0-.1,3.6-10.09,10.7-8.1,2.48.7,3.82,3.19,3.77,7-.08,5.15-2.81,12.11-5.89,12.88A8.72,8.72,0,0,1,308.5,170.18Z"
style="fill: rgb(38, 50, 56); transform-origin: 309.351px 159.959px;" id="elnn5420fomnl" class="animable"></path><path d="M304,156.23c-.76,1.61-3.72.19-4.81-1.22a4.17,4.17,0,0,1,0-5c-2.31-.67-4.91-1.71-5.91-3.9a7.36,7.36,0,0,1-.43-3.49c.21-3.21,1-6.41.67-9.61s-2.31-6.55-5.48-7.14c0,0-11,19.69-31.75,11.74,0,0-9.25,4.42-13.46-3.15s1.47-15.57,13.25-20.62c0,0-7.36-.21-7.15-4S267,105,279.63,107.77c0,0-1.49-6.63,14.08-4.74s20.61,6.28,22.52,17.15c11.41,6.22-1.41,31.86-1.41,31.86S308.79,146,304,156.23Z"
style="fill: rgb(38, 50, 56); transform-origin: 281.203px 129.818px;" id="elri6rgs7sugg" class="animable"></path><path d="M247.17,134.82A16.23,16.23,0,0,0,246,148.4l3.21,5-.5-17Z"
style="fill: rgb(38, 50, 56); transform-origin: 247.12px 144.11px;" id="elaha23tb5itm" class="animable"></path><path d="M315.24,122.2a.4.4,0,0,1-.15,0,.5.5,0,0,1-.32-.63c4.24-13-4.81-19-5.2-19.29a.51.51,0,0,1-.14-.7.5.5,0,0,1,.69-.14c.1.06,10.08,6.74,5.6,20.44A.5.5,0,0,1,315.24,122.2Z"
style="fill: rgb(38, 50, 56); transform-origin: 313.112px 111.783px;" id="elmlnekg6res" class="animable"></path><path d="M252.75,132.28a13.38,13.38,0,0,1-8.34-2.64.5.5,0,1,1,.61-.78c3.1,2.42,7.77,3.07,12.51,1.74,4.1-1.16,7.82-3.53,11.41-5.82l.48-.31c4-2.55,8-4.92,12.36-5.87a20.61,20.61,0,0,1,15.66,3c2.23,1.48,3.41,3.14,3.49,4.92a.5.5,0,0,1-.48.52.47.47,0,0,1-.52-.47c-.07-1.45-1.09-2.84-3-4.14a19.88,19.88,0,0,0-14.9-2.81c-4.24.91-8,3.14-12,5.73l-.48.31c-3.66,2.33-7.44,4.74-11.68,5.94A18.72,18.72,0,0,1,252.75,132.28Z"
style="fill: rgb(255, 255, 255); transform-origin: 272.545px 125.22px;" id="elyhddz0qu44" class="animable"></path><path d="M304.57,120.76a.5.5,0,0,1-.48-.37,12.47,12.47,0,0,0-7.93-7.89c-3.45-1.23-7.61-1.39-12.72-.49-2,.35-4,.83-5.92,1.29-2.25.53-4.58,1.09-6.92,1.45-3.48.55-8.16.53-10.61-2.65a.5.5,0,1,1,.79-.61c2.15,2.79,6.45,2.78,9.67,2.27,2.29-.36,4.61-.91,6.84-1.44,1.95-.46,4-.94,6-1.29,5.29-.94,9.61-.76,13.22.53a13.45,13.45,0,0,1,8.56,8.56.5.5,0,0,1-.35.62Z"
style="fill: rgb(255, 255, 255); transform-origin: 282.478px 115.599px;" id="el91emvfen9sl" class="animable"></path></g></g><g id="progess-bar--inject-212" class="animable"
style="transform-origin: 249.43px 413.67px;"><g id="progress-bar--inject-212" class="animable"
style="transform-origin: 249.43px 413.67px;"><path d="M418.6,418.57H80.26a3.44,3.44,0,0,1-3.43-3.43h0a3.43,3.43,0,0,1,3.43-3.43H418.6a3.43,3.43,0,0,1,3.43,3.43h0A3.44,3.44,0,0,1,418.6,418.57Z"
style="fill: rgb(38, 50, 56); transform-origin: 249.43px 415.14px;" id="elnm3v8nzguin" class="animable"></path><path d="M416.72,415.64h-336a.5.5,0,0,1,0-1h336a.5.5,0,0,1,0,1Z"
style="fill: rgb(255, 255, 255); transform-origin: 248.72px 415.14px;" id="el7atwybiu3tp" class="animable"></path><g id="eljtht5ktf03m"><circle cx="100.44" cy="413.67" r="12.98"
style="fill: rgb(255, 255, 255); transform-origin: 100.44px 413.67px; transform: rotate(-47.27deg);" class="animable"></circle></g><path d="M100.44,427.15a13.48,13.48,0,1,1,13.48-13.48A13.49,13.49,0,0,1,100.44,427.15Zm0-26a12.48,12.48,0,1,0,12.48,12.48A12.5,12.5,0,0,0,100.44,401.19Z"
style="fill: rgb(38, 50, 56); transform-origin: 100.44px 413.67px;" id="elogzr6p4ppo" class="animable"></path><path d="M107.3,413.67a6.86,6.86,0,1,1-6.86-6.86A6.86,6.86,0,0,1,107.3,413.67Z"
style="fill: rgb(227, 146, 146); transform-origin: 100.44px 413.67px;" id="elchzinj05p2d" class="animable"></path><path d="M100.44,421a7.36,7.36,0,1,1,7.36-7.36A7.37,7.37,0,0,1,100.44,421Zm0-13.72a6.36,6.36,0,1,0,6.36,6.36A6.37,6.37,0,0,0,100.44,407.31Z"
style="fill: rgb(38, 50, 56); transform-origin: 100.44px 413.64px;" id="elidp4wwipvm" class="animable"></path></g></g><defs>     <filter id="active" height="200%">         <feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>                <feFlood flood-color="#32DFEC" flood-opacity="1" result="PINK"></feFlood>        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>        <feMerge>            <feMergeNode in="OUTLINE"></feMergeNode>            <feMergeNode in="SourceGraphic"></feMergeNode>        </feMerge>    </filter>    <filter id="hover" height="200%">        <feMorphology in="SourceAlpha" result="DILATED" operator="dilate" radius="2"></feMorphology>                <feFlood flood-color="#ff0000" flood-opacity="0.5" result="PINK"></feFlood>        <feComposite in="PINK" in2="DILATED" operator="in" result="OUTLINE"></feComposite>        <feMerge>            <feMergeNode in="OUTLINE"></feMergeNode>            <feMergeNode in="SourceGraphic"></feMergeNode>        </feMerge>            <feColorMatrix type="matrix" values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "></feColorMatrix>    </filter></defs>
</svg>
`}} />
)
