import React, { useState, useEffect } from "react";

const videoServiceBaseUrl = 'https://vimeo.com/';

/**
 * https://developer.vimeo.com/player/sdk/basics
 * 
 * Specifying the video ID or URL as HTML attributes
 */

 // TODO USE OPTIONS JAVASCRIPT API INSTEAD OF HTML ATTRIBUTES (SEE https://developer.vimeo.com/player/sdk/embed)
 // TODO "NOTE: We strongly recommend the JavaScript method. Use the HTML method only as a last resort."
export default ({ player: { videoPlayerId, videoId } }) => (
  <div
    id={videoPlayerId}
    className="video-player"
    data-vimeo-id={videoId}
    data-vimeo-width="660"
    data-vimeo-controls="false"
    data-vimeo-autoplay="false"
    data-vimeo-autopause="false"
    data-vimeo-loop="false"
    style={{ width: '100%', boxSizing: 'border-box', boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, .25)' }}
  />
);

{/*
  <iframe src={`https://player.vimeo.com/video/${videoId}`} width="{video_width}" height="{video_height}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
*/}


/*
let startTime;
let stopTime;
//! let paused;
*/


/**
 * 
 */
export function VideoPlayerConstructor({ videoPlayerId, videoId, timeUpdateHandler, onReady }) {
  //let _startTime;
  //let _stopTime;
  //let video;// = document.querySelector(`#${videoPlayerId}`);

  this.videoPlayerId = videoPlayerId;
  this.videoId = videoId;
  this.timeUpdateHandler = timeUpdateHandler;

  setTimeout(() => {
    const Vimeo = window['Vimeo'];
    this.videoPlayer = new Vimeo.Player(videoPlayerId);

    if (onReady) {
      onReady(); // initVideoPlayer();
    }
  }, 1);
}

/*
    VideoPlayerConstructor.prototype.play = function load(videoData) {
      youtubePlayer.loadVideoById({
        //'videoId': 'bHQqvYy5KYo',
        'videoId': videoData.source, //! 'l9ee_eN4AYI',
        //'startSeconds': 5,
        //'endSeconds': 60,
        'suggestedQuality': 'large',
      });

      //! this.play(startTime, stopTime);
    };
*/

VideoPlayerConstructor.prototype.play = function (startTime = 0, duration = 9999999) {
  this._startTime = startTime;
  this._stopTime = startTime + duration;
  console.log("VideoPlayer.play: _startTime=" + this._startTime + " _stopTime=" + this._stopTime);
  //! currClip.video.currentTime = startTime;
  this.videoPlayer.play();//! _startTime, _stopTime);

//! TEMP!!! null TO AVOID AUTOMATIC REWIND (should just unpause if was paused)
if (startTime !== null)
  setTimeout(() => {
      this.seek(this._startTime);
  }, 1000);

  if (this.timeUpdateHandler) {
    //! currClip.video.addEventListener('timeupdate', this.timeUpdateHandler);
    // TODO USE onPlayerStateChange STATE INSTEAD OF THE TIMER TO DETECT STOPPING OF PLAY ETC
    setTimeout(this.timeUpdateHandler, duration * 1000);
  }
};

VideoPlayerConstructor.prototype.pause = function pause() {
  this.videoPlayer.pause();
};

VideoPlayerConstructor.prototype.seek = function seek(time) {
  this.videoPlayer.setCurrentTime(time);
};

VideoPlayerConstructor.prototype.seekEnd = function seekEnd() {
  this.videoPlayer.getDuration().then((duration) => {
    this.seek(duration);
  });
};

VideoPlayerConstructor.prototype.seekBeginning = function seekBeginning() {
  //youtubePlayer.seekTo(_startTime);
  //youtubePlayer.playVideo(_startTime, _stopTime);
//TODO MOVE TO HIGHER LEVEL VIDEOPLAYER REWIND FN
//TODO  this.play(this._startTime, this._stopTime - this._startTime);
  this.seek(0);
};

VideoPlayerConstructor.prototype.stop = function stop() {
  //! this.videoPlayer.stop();
  this.videoPlayer.pause();
};

VideoPlayerConstructor.prototype.isPaused = function isPaused() {
};
